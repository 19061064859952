import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { GoldenRulesDialogComponent } from '@shared/golden-rules-dialog/golden-rules-dialog.component';
import { Auth } from 'aws-amplify';
import { FormMode } from '../../core/enums/form-mode.enum';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  // TODO: enum
  // signin/create/followup/modify/detail
  @Input() page: string;

  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private userService: UserService,

  ) { }

  public onFolloWUp(): void {
    this.router.navigate(['campaigns'])
  }

  public onBackToHomePage(): void {
    this.router.navigate(['campaigns', FormMode.CREATE])
  }

  public onSignOut(): void {
    Auth.signOut().then(() => {
      this.userService.logout()
      this.router.navigate(['signin'])
    })
  }

  public onOpenGoldenRules(): void {
    // open dialog
    this.dialog.open(GoldenRulesDialogComponent, {
      panelClass: 'generated-url-dialog-container', 
      disableClose: true,
    })
  }


}
