import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms'
import { FormName } from '@core/enums/form-names.enum'
import { IRessource } from '@core/models/ressource.model'
import { ResourceService } from '@core/services/resource.service'
import { ProductFormControl } from '@features/campaigns/models/campaign-form.model'
import { ICampaign } from '@features/campaigns/models/campaign.model'
import { CampaignFormService } from '@features/campaigns/services/campaign-form.service'
import { takeUntil } from 'rxjs/operators'
import { BaseComponent } from '@core/base.component'
import { FormMode, ListSortMode } from '@core/enums'

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ProductFormComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() errors: any

  @Input() mode: string

  @Input() sourceCampaign?: ICampaign

  public productFormName = FormName.PRODUCT

  private campaignForm!: UntypedFormGroup

  private productForm!: UntypedFormGroup

  public productData!: any

  public productLines: IRessource[]

  public families: IRessource[]

  public subFamilies: IRessource[]

  constructor(
    private readonly parent: FormGroupDirective,
    private readonly fb: UntypedFormBuilder,
    private readonly resourceService: ResourceService,
    private readonly campaignFormService: CampaignFormService,
  ) {
    super()
    super.ngOnDestroy()
  }

  ngOnInit(): void {
    this.initForm()
    this.initData()
    this.handleFormChanges()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sourceCampaign.currentValue) {
      this.populateProductForm()
      this.resolveProductFormState()
    }
  }

  //   PRIVATE METHODS

  private initForm(): void {
    this.campaignForm = this.parent.form
    this.campaignForm.addControl(
      FormName.PRODUCT,
      this.fb.group({
        productLine: ['', [Validators.required]],
        family: [{ value: '', disabled: true }, [Validators.required]],
        subFamily: [{ value: '', disabled: true }, [Validators.required]],
        switchToggleProducts: [],
      }),
    )
    this.productForm = this.campaignForm.controls[FormName.PRODUCT] as UntypedFormGroup
  }

  private initData(): void {
    this.productData = this.resourceService.getResourcesByForm(FormName.PRODUCT)
    this.productLines = this.productData.productLines
  }

  private handleFormChanges(): void {
    // product changes
    this.productForm.controls[ProductFormControl.PRODUCT_LINE].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.handleProductLineChanges(value))

    // family changes
    this.productForm
      .get(ProductFormControl.FAMILY)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleFamilyChanges(value)
      })

    // switch toggle products
    this.productForm
      .get('switchToggleProducts')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleSwitchToggleProductsChanges(value)
      })
  }

  private handleProductLineChanges(value: string): void {
    // Reset potential dependent field value
    this.productForm.get(ProductFormControl.FAMILY).reset()
    this.productForm.get(ProductFormControl.SUB_FAMILY).reset()

    this.families = this.campaignFormService.resolveDependencies(
      value,
      this.productData.families,
      ListSortMode.PER_VALUE,
    )
    this.campaignFormService.resolveFieldState(
      ProductFormControl.FAMILY,
      this.productForm,
      this.families,
    )
  }

  private handleFamilyChanges(value: string): void {
    // Reset dependent field value
    this.productForm.get(ProductFormControl.SUB_FAMILY).reset()

    this.subFamilies = this.campaignFormService.resolveDependencies(
      value,
      this.productData.subFamilies,
      ListSortMode.PER_VALUE,
    )
    this.campaignFormService.resolveFieldState(
      ProductFormControl.SUB_FAMILY,
      this.productForm,
      this.subFamilies,
    )
  }

  private handleSwitchToggleProductsChanges(value): void {
    if (!!value) {
      this.productForm.get(ProductFormControl.PRODUCT_LINE).setValue('NSP')
      this.productForm.get(ProductFormControl.PRODUCT_LINE).disable()
      this.productForm.get(ProductFormControl.FAMILY).disable()
      this.productForm.get(ProductFormControl.SUB_FAMILY).disable()
    } else {
      this.productForm.get(ProductFormControl.PRODUCT_LINE).reset()
      this.productForm.get(ProductFormControl.PRODUCT_LINE).enable()
    }
  }

  private populateProductForm(): void {
    if (this.mode !== FormMode.CREATE) {
      this.campaignFormService.populateProductForm(
        this.productForm,
        this.sourceCampaign,
      )
    }
  }

  private resolveProductFormState(): void {
    if (this.mode === FormMode.DISPLAY) {
      this.productForm.disable({ emitEvent: false })
    }
  }
}
