import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from '@core/enums';
import { UserService } from '@core/services/user.service'

@Component({
  selector: 'app-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss']
})
export class SigninPageComponent implements OnInit {

  isLoading = true;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.userService.userToken) {
      this.router.navigate(['campaigns', FormMode.CREATE])
      this.isLoading = false
    }
    setTimeout(() => {
      this.isLoading = false
    }, 3000);
  }

}
