<app-header page='signin'>Authentication</app-header>
<div class="container" [ngClass]="{loaded: !isLoading}">
  <div class="content">
    <div class="help-info">
      <div class="wrapper">
        <mat-icon>info</mat-icon>
        <span> For new account creation, please contact your SEB referent</span>
      </div>
    </div>
    <app-signin></app-signin>
  </div>
</div>

<app-loader *ngIf="isLoading"></app-loader>
