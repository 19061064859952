<h1 mat-dialog-title>Delete campaign url</h1>
<mat-dialog-content class="mat-typography">
  <p>Do you really want to delete permanently this campaign ?</p>
  <ul>
    <li>Campaign ID : {{ data.campaignId }}</li>
    <li>Campaign name : {{ data.campaignName }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="onDelete()"
  >
    Delete
  </button>
</mat-dialog-actions>
