<h1 mat-dialog-title>Golden Rules</h1>
<mat-dialog-content>
  <p>
    A proper use of the Campaign Naming Builder is strategic and a pre-requisite
    for our analytics and the campaign dashboard automation:
  </p>
  <ul>
      <li>Try to do your best to define the right values in the various dropdown lists</li>
      <li>Never edit manually the string generated: copy and paste it into the activation tool “as is”</li>
      <li>Never reuse an old campaign naming string for a new campaign, always re-generate a campaign name for your activation campaign.</li>
      <li>If one of your product is absent from the list, please :
          <ul>
              <li>Set up the campaign using the sub-family is belonging to</li>
              <li>Or ask to add the product to the list if you consider the product as key => For information, only the products considered as the most strategic have been added to the « Product or Sub Family » dropdown list.</li>
          </ul>
      </li>

      <li>Always use the optional free text for any information you would like to keep or add in the campaign naming</li>
  </ul>
  <p>In case of any doubt or question regarding this tool, please contact your continental or central lead or GSEB internal referent <a href="mailto:fctis_support_datamarketing@groupeseb.com">(fctis_support_datamarketing@groupeseb.com)</a></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="close()">Close</button>
</mat-dialog-actions>
