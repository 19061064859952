import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header-interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { BaseComponent } from './base.component';
@NgModule({
  declarations: [BaseComponent],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [BaseComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true}
  ]
})
export class CoreModule { }
