
// Markets
import markets from '@core/resources/markets.json';
import languages from '@core/resources/languages.json';
import brands from '@core/resources/brands.json';
import eventTypes from '@core/resources/event_types.json';
import shared_eventTypes from '@core/resources/shared_event_types.json';

// Product lines
import productLines from '@core/resources/product_lines.json';
import productFamily from '@core/resources/families.json';
import productSubFamily from '@core/resources/sub_families.json';

// Channels
import channels from '@core/resources/channels.json';
import formats from '@core/resources/formats.json';
import funnels from '@core/resources/funnel_phases.json';
import engines from '@core/resources/engines.json';
import buyingMethods from '@core/resources/buying_methods.json';
import campaignTypes from '@core/resources/campaign_types.json';

// Media types
import activationTypes from '@core/resources/media_types.json';

// Duration Types
import durationTypes from '@core/resources/durations.json';

// Consumer journeys
import objectives from '@core/resources/objectives.json';
import cyclesJourneys from '@core/resources/cycles_journeys.json';

// Medias
import medias from '@core/resources/medias.json';
import campaignLinkRedirections from '@core/resources/campaign_link_redirections.json'
import fundingSources from '@core/resources/funding_sources.json'

// Consumer types
import consumerTypes from '@core/resources/consumer_types.json';

// Iterations
import iterations from '@core/resources/iterations.json';

// CRM
import communications from '@core/resources/communications.json';
import stages from '@core/resources/stages.json';
import crmCampainTypes from '@core/resources/crm_campaign_types.json'; 
import crmFunnels from '@core/resources/funnel_phases.json';

export const Resources =  {
    activationTypes,
    brands,
    buyingMethods,
    campaignLinkRedirections,
    campaignTypes,
    channels,
    communications,
    consumerTypes,
    crmCampainTypes,
    crmFunnels,
    cyclesJourneys,
    durationTypes,
    engines,
    eventTypes,
    formats,
    fundingSources,
    funnels,
    iterations,
    languages,
    markets,
    medias,
    objectives,
    productFamily,
    productLines,
    productSubFamily,
    shared_eventTypes,
    stages,
};