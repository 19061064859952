<h1 mat-dialog-title>Campaign name generated</h1>
<mat-dialog-content>
  <div class="campaign-url-container">
    <mat-form-field appearance="outline" [disabled]="true">
      <mat-label>Short campaign name</mat-label>

      <input matInput [disabled]="true" [value]="data.xSmallUrl" />
      <mat-icon matSuffix color="primary" matTooltip="copy short campaign name" (click)="onCopyToClipboard(data.xSmallUrl)">file_copy</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" [disabled]="true">
      <mat-label>Medium campaign name</mat-label>

      <input matInput [disabled]="true" [value]="data.shortUrl" />
      <mat-icon matSuffix color="primary" matTooltip="copy medium campaign name" (click)="onCopyToClipboard(data.shortUrl)">file_copy</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" [disabled]="true">
      <mat-label>Long campaign name</mat-label>

      <input matInput [disabled]="true" [value]="data.fullUrl" />
      <mat-icon matSuffix color="primary" matTooltip="copy long campaign name" (click)="onCopyToClipboard(data.fullUrl)">file_copy</mat-icon>
    </mat-form-field>

    <div class="action-buttons">
      <button mat-stroked-button color="primary" class="secondary" (click)="onCreateNew()">
        Create new
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <button mat-stroked-button color="primary" class="secondary" (click)="onEdit()">
        Modify
        <mat-icon class="edit">edit</mat-icon>
      </button>
      <button mat-stroked-button color="primary" class="secondary" (click)="onDuplicate()">
        Duplicate
        <mat-icon>file_copy</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Tracked url -->
  <div class="tracked-url-container">
    <h1 mat-dialog-title>Need a tracked URL?</h1>

    <form [formGroup]="trackedUrlForm">
      <mat-form-field appearance="outline" class="column-field">
        <mat-label>Landing page (URL)</mat-label>
        <input matInput formControlName="landingPageUrl" placeholder="https://www.URL.com" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="column-field">
        <mat-label>UTM content (optional)</mat-label>
        <input matInput formControlName="utmContent" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="column-field">
        <mat-label>UTM term (optional)</mat-label>
        <input matInput formControlName="utmTerm" />
      </mat-form-field>
    </form>

    <mat-label>Tracked URL</mat-label>
    <mat-form-field appearance="outline" [disabled]="true" class="tracked-url">
      <input matInput [disabled]="true" [value]="trackedUrl" />
      <mat-icon matSuffix color="primary" matTooltip="copy tracked url" (click)="onCopyToClipboard(trackedUrl)">file_copy</mat-icon>
    </mat-form-field>
    <em>URL too long? Use
      <a href="https://bitly.com/" target="_blank">https://bitly.com/</a> to
      shorten your landing page URL</em>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close (click)="close()">Close</button>
</mat-dialog-actions>