import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private defaultConfiguration: MatSnackBarConfig<any> = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: 'snack-notification',
    duration: 2000,
  }

  constructor(public snackBar: MatSnackBar) {}

  action(message: string, action: string, duration?: number): Observable<void> {
    return this.openSnackbar(message, action, duration ? { duration } : undefined).onAction();
  }

  notify(message:string): void {
    this.openSnackbar(message, undefined, { panelClass: 'snack-info'});
  }

  error(message: string): void {
    this.openSnackbar(message, undefined, { panelClass: 'snack-error', duration: 3000})
  }

  success(message: string): void {
    this.openSnackbar(message, undefined, { panelClass: 'snack-success' });
  }

  requiredFields(message: string): void {
    this.openSnackbar(message, undefined, { panelClass: 'snack-required' });
  }

  private openSnackbar(message: string, action?: string, configuration: MatSnackBarConfig<any> = {}): MatSnackBarRef<SimpleSnackBar> {
    const currentConfiguration = Object.assign({}, this.defaultConfiguration, configuration);
    return this.snackBar.open(message, action, currentConfiguration);
  }
}
