import { Clipboard } from '@angular/cdk/clipboard'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NotificationService } from '@core/services/notification.service'
import { debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators'
import { FormMode } from 'src/app/core/enums/form-mode.enum'
import { BaseComponent } from 'src/app/core/base.component'

enum TrackedUrl {
  LANDING_PAGE_URL = 'landingPageUrl',
  UTM_CONTENT = 'utmContent',
  UTM_TERM = 'utmTerm',
}
@Component({
  selector: 'app-campaign-url-dialog',
  templateUrl: './campaign-url-dialog.component.html',
  styleUrls: ['./campaign-url-dialog.component.scss'],
})
export class CampaignUrlDialogComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  generatedUrl: string
  trackedUrl: string

  trackedUrlForm: UntypedFormGroup
  landingPageUrl: string = ''
  utmContent: string
  utmTerm: string

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      campaignUrlId: string
      xSmallUrl: string
      shortUrl: string
      fullUrl: string
      utm_source: string
      utm_medium: string
    },
    private readonly dialogRef: MatDialogRef<CampaignUrlDialogComponent>,
    private readonly clipboard: Clipboard,
    private readonly fb: UntypedFormBuilder,
    private readonly notificationService: NotificationService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.initForm()
    this.buildTrackedUrl()
    this.handleFormChanges()
  }

  initForm() {
    this.trackedUrlForm = this.fb.group({
      landingPageUrl: [''],
      utmContent: [''],
      utmTerm: [''],
    })
  }

  // Prevent from spaces
  cleanTrackedUrlFieldValue(value: string, field: string): string {
    const forbiddenCharacters = /\s+/g
    if (forbiddenCharacters.test(value)) {
      this.trackedUrlForm
        .get(field)
        .setValue(value.replace(forbiddenCharacters, ''))
    }
    return value
  }

  handleFormChanges() {
    this.trackedUrlForm
      .get(TrackedUrl.LANDING_PAGE_URL)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.landingPageUrl = this.cleanTrackedUrlFieldValue(
          value,
          TrackedUrl.LANDING_PAGE_URL,
        )
        this.buildTrackedUrl()
      })

    this.trackedUrlForm
      .get(TrackedUrl.UTM_CONTENT)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.utmContent = this.cleanTrackedUrlFieldValue(value, TrackedUrl.UTM_CONTENT)
        this.buildTrackedUrl()
      })

    this.trackedUrlForm
      .get(TrackedUrl.UTM_TERM)
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged(),
        )
      .subscribe((value) => {
        this.utmTerm = this.cleanTrackedUrlFieldValue(value, TrackedUrl.UTM_TERM)
        this.buildTrackedUrl()
      })
  }

  onCopyToClipboard(source: string): void {
    const urlCopied = this.clipboard.copy(source)
    if (!!urlCopied) {
      this.openSnackBar()
    }
  }

  close(): void {
    this.dialogRef.close({ action: 'close' })
  }

  onCreateNew(): void {
    this.dialogRef.close({ action: FormMode.CREATE })
  }

  onEdit(): void {
    this.dialogRef.close({
      action: FormMode.UPDATE,
      campaignUrlId: this.data.campaignUrlId,
    })
  }

  onDuplicate(): void {
    this.dialogRef.close({
      action: FormMode.DUPLICATE,
      campaignUrlId: this.data.campaignUrlId,
    })
  }

  openSnackBar() {
    this.notificationService.success('Url successfully copied!')
  }

  buildTrackedUrl() {
    //In order to avoid multiple question marks at the bstart of the tracked URL
    const trackedUrlSeparator = this.landingPageUrl.includes('?', 0) ? '&' : '?'

    this.trackedUrl = `${this.landingPageUrl}${trackedUrlSeparator}utm_source=${this.data.utm_source}&utm_medium=${this.data.utm_medium}`
    this.trackedUrl += !!this.utmContent
      ? `&utm_content=${this.utmContent}`
      : ``
    this.trackedUrl += !!this.utmTerm ? `&utm_term=${this.utmTerm}` : ``
    this.trackedUrl += `&utm_campaign=${this.data.shortUrl}`
  }
}
