import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'

const routes: Routes = [
  {
    path: 'campaigns/create',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/campaign-create-page/campaign-create-page.module').then(
        (m) => m.CampaignCreatePageModule,
      ),
      data: {
        title: 'Campaign Naming Builder'
      }
  },
  {
    path: 'campaigns/update/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/campaign-update-page/campaign-update-page.module').then(
        (m) => m.CampaignUpdateModule,
      ),
      data: {
        title: 'Campaign Naming Update'
      }
  },
  {
    path: 'campaigns/duplicate/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/campaign-duplicate-page/campaign-duplicate-page.module').then(
        (m) => m.CampaignDuplicateModule,
      ),
      data: {
        title: 'Campaign Naming Update'
      }
  },
  {
    path: 'campaigns/detail/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/campaign-details-page/campaign-details-page.module').then(
        (m) => m.CampaignDetailsPageModule,
      ),
      data: {
        title: 'Campaign Naming Details'
      }
  },
  {
    path: 'campaigns',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/campaign-list-page/campaign-list-page.module').then(
        (m) => m.CampaignListModule,
      ),
      data: {
        title: 'Campaign Referential (Url Builder)'
      }
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./pages/signin-page/signin-page.module').then(
        (m) => m.SigninPageModule,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signin',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPageModule,
      ),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
