<header>
  <div class="header-wrapper container">
    <div class="header-logo" (click)="onBackToHomePage()">
      <img src="assets/img/logo_seb.png" alt="logo-seb" />
    </div>
    <div class="header-content">
      <div class="header-title">
        <span>
          <ng-content></ng-content>
        </span>
      </div>
    </div>


    <div class="action-button">
      <div *ngIf="page !== 'followup' && page !== 'signin'">
        <button mat-stroked-button (click)="onFolloWUp()">
          Campaigns Follow-up
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>

    </div>
    
    <div *ngIf="page !== 'signin'">
      <button mat-icon-button class="signout" (click)="onSignOut()"  matTooltip="Sign out">
        <mat-icon>logout</mat-icon>
      </button>
    </div>

    <div class="help" *ngIf="page !== 'signin'">
      <button
        mat-mini-fab
        aria-label="Need help?"
        [matMenuTriggerFor]="menu"
        matTooltip="Need Help?"
      >
        <mat-icon>help_outline</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onOpenGoldenRules()">
          <mat-icon>info</mat-icon>
          <span>Golden Rules</span>
        </button>
        <a
          href="https://tmp-urlbuilder.s3.eu-west-1.amazonaws.com/URL_Builder_Guide_V3.pdf"
          target="_blank"
        >
          <button mat-menu-item>
            <mat-icon>help_outline</mat-icon>
            <span>How to use it</span>
          </button>
        </a>
        <button mat-menu-item>
          <mat-icon> contact_support</mat-icon>
          <span
            >Need Help ?
            <a href="mailto:fctis_support_datamarketing@groupeseb.com"
              >fctis_support_datamarketing@groupeseb.com</a
            ></span
          >
        </button>
      </mat-menu>
    </div>
  </div>
</header>
