import { Injectable } from '@angular/core'
import { AmplifyUser } from '@aws-amplify/ui'
import { CognitoUser } from 'amazon-cognito-identity-js'

import { Auth } from 'aws-amplify'
import { StorageKey } from 'src/app/core/enums'
@Injectable({
  providedIn: 'root',
})
export class UserService {
  isLogged = false
  constructor() {}

  // Get token
  async getUserToken(): Promise<string> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser()
    const token = user.getSignInUserSession().getIdToken().getJwtToken();

    return token;
  }

  getLoggedUser() {
    return this.loggedUser
  }

  logout() {
    localStorage.removeItem(StorageKey.IDENTITY)
    localStorage.removeItem(StorageKey.USER_TOKEN)
  }

  set loggedUser(value: AmplifyUser | undefined) {
    localStorage.removeItem(StorageKey.IDENTITY)
    localStorage.setItem(StorageKey.IDENTITY, JSON.stringify(value))
  }

  get loggedUser(): AmplifyUser | undefined {
    return JSON.parse(localStorage.getItem(StorageKey.IDENTITY))
  }

  get userToken(): string | undefined {
    return localStorage.getItem(StorageKey.USER_TOKEN) || undefined
  }

  set userToken(value: string | undefined) {
    localStorage.removeItem(StorageKey.USER_TOKEN)
    localStorage.setItem(StorageKey.USER_TOKEN, value)
  }

  /**
   * Get markets
   * @returns string "FR AR ..."
   */
  getUserMarket(): string {
    return this.loggedUser?.attributes['custom:markets']
  }

  /**
   * Get user email
   * @returns string
   */
  getUserEmail(): string {
    return this.loggedUser?.attributes.email
  }
}
