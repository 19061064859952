import { ActivationType } from '@core/enums'
import { Dayjs } from 'dayjs'

export interface ICampaignForm {
  activationType: ActivationType
  brand: string
  crmType?: ICrmForm
  durationType: string
  eventType: string
  language: string
  market: string
  mediaType?: IMediaForm
  optional: string
  productType: IProductForm
  startMonth: Dayjs
}

export enum CampaignFormControl {
  ACTIVATION_TYPE = 'activationType',
  BRAND = 'brand',
  DURATION_TYPE = 'durationType',
  EVENT_TYPE = 'eventType',
  LANGUAGE = 'language',
  MARKET = 'market',
  OPTIONAL = 'optional',
}

export interface IProductForm {
  productLine: string
  family: string
  subFamily: string
}

export enum ProductFormControl {
  PRODUCT_LINE = 'productLine',
  FAMILY = 'family',
  SUB_FAMILY = 'subFamily',
}

export interface ICrmForm {
  campaignType: string
  channel: string
  communication: string
  consumerType: string
  cycleOrJourney: string
  funnel: string
  objective: string
  stage: string
  campaignLinkRedirection: string
  fundingSource: string
}

export enum CrmFormControl {
  CAMPAIGN_LINK_REDIRECTION = 'campaignLinkRedirection',
  CAMPAIGN_TYPE = 'campaignType',
  CHANNEL = 'channel',
  COMMUNICATION = 'communication',
  CONSUMER_TYPE = 'consumerType',
  CYCLE_OR_JOURNEY = 'cycleOrJourney',
  FUNDING_SOURCE = 'fundingSource',
  FUNNEL = 'funnel',
  OBJECTIVE = 'objective',
  STAGE = 'stage',
}

export interface IMediaForm {
  buyingMethod: string
  campaignLinkRedirection: string
  campaignType: string
  channel: string
  engine: string
  engineFree: string
  format: string
  fundingSource: string
  funnel: string
}

export enum MediaFormControl {
  BUYING_METHOD = 'buyingMethod',
  CAMPAIGN_LINK_REDIRECTION = 'campaignLinkRedirection',
  CAMPAIGN_TYPE = 'campaignType',
  CHANNEL = 'channel',
  ENGINE = 'engine',
  ENGINE_FREE = 'engineFree',
  FORMAT = 'format',
  FUNDING_SOURCE = 'fundingSource',
  FUNNEL = 'funnel',
}
