<div [formGroupName]="mediaFormName" id="mediaForm">
  <section>
    <h2>Activation Strategy</h2>

    <!-- channel -> funnels -->
    <mat-form-field appearance="outline">
      <mat-label>Funnel phase</mat-label>
      <mat-select formControlName="funnel">
        <mat-option *ngFor="let funnel of funnels" [value]="funnel.value">{{ funnel.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- Campaign link redirection -->
    <mat-form-field appearance="outline">
      <mat-label>Campaign link redirection</mat-label>
      <mat-select formControlName="campaignLinkRedirection">
        <mat-option *ngFor="let link of campaignLinkRedirections" [value]="link.value">{{ link.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- Campaign link redirection -> funding source -->
    <mat-form-field appearance="outline">
      <mat-label>Funding source</mat-label>
      <mat-select formControlName="fundingSource">
        <mat-option *ngFor="let source of fundingSources" [value]="source.value">{{ source.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>
  </section>

  <section>
    <h2>Activation lever</h2>

    <!-- channel -->
    <mat-form-field appearance="outline">
      <mat-label>Channel</mat-label>
      <mat-select formControlName="channel">
        <mat-option *ngFor="let channel of channels" [value]="channel.value">{{
          channel.name
          }}</mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- channel -> format -->
    <mat-form-field appearance="outline">
      <mat-label>Format</mat-label>
      <mat-select formControlName="format">
        <mat-option *ngFor="let format of formats" [value]="format.value">{{
          format.name
          }}</mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>
  </section>

  <section>
    <h2>Activation Details</h2>
    <!-- channel -> engine -->
    <mat-form-field appearance="outline">
      <mat-label>Engine</mat-label>
      <mat-select formControlName="engine">
        <mat-option *ngFor="let engine of engines" [value]="engine.value">{{
          engine.name
          }}</mat-option>
        <mat-option value="engineFree">Other engine</mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- channel -> free engine -->
    <mat-form-field appearance="outline">
      <input matInput placeholder="Free engine" formControlName="engineFree" maxlength="10" />
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- channel -> buyingMethod -->
    <mat-form-field appearance="outline">
      <mat-label>Buying method</mat-label>
      <mat-select formControlName="buyingMethod">
        <mat-option *ngFor="let method of buyingMethods" [value]="method.value">{{ method.name }}</mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>

    <!-- channel -> campaignType -->
    <mat-form-field appearance="outline">
      <mat-label>Type of campaign</mat-label>
      <mat-select formControlName="campaignType">
        <mat-option *ngFor="let type of campaignTypes" [value]="type.value">{{
          type.name
          }}</mat-option>
      </mat-select>
      <mat-error>
        <mat-icon class="icon-error">error</mat-icon>
        {{ errors.required }}
      </mat-error>
    </mat-form-field>
  </section>
</div>