<!-- filter line -->
<div class="campaign-list-toolbar">
  <div class="campaign-list-toolbar-buttons">
    <button mat-stroked-button (click)="onCreateNew()">Create</button>
    <button mat-flat-button color="primary" (click)="onGetMyCampaigns()">
      My Campaigns
    </button>
  </div>
  <div class="campaign-list-filter-wrapper">
    <div class="campaign-list-filter-fields">
      <!-- ID filter -->
      <mat-form-field appearance="standard" class="filter">
        <mat-label>SEB campaign Id</mat-label>
        <input
          matInput
          [formControl]="campaignIdFilter"
          maxlength="10"
          type="text"
        />
      </mat-form-field>

      <!-- Product lines filter -->
      <mat-form-field appearance="standard" class="filter">
        <mat-label>Product Line</mat-label>
        <mat-select [formControl]="productsFilter" disableOptionCentering>
          <mat-option>None</mat-option>
          <mat-option
            *ngFor="let product of productLines"
            [value]="product.value"
            >{{ product.props.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Markets filter -->
      <mat-form-field appearance="standard" class="filter">
        <mat-label>Market</mat-label>
        <mat-select [formControl]="marketsFilter" disableOptionCentering>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let market of markets" [value]="market.value">{{
            market.props.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Brands filter -->
      <mat-form-field appearance="standard" class="filter">
        <mat-label>Brand</mat-label>
        <mat-select [formControl]="brandsFilter" disableOptionCentering>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let brand of brands" [value]="brand.value">{{
            brand.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="campaign-list-filter-action-buttons">
      <button
        mat-flat-button
        color="primary"
        (click)="searchByFilter()"
        [disabled]="hasNoFilters"
      >
        <mat-icon>search</mat-icon>search
      </button>
      <button mat-stroked-button (click)="clearFilter()">
        <mat-icon>close</mat-icon>clear
      </button>
    </div>
  </div>
</div>

<div>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="campaigns-follow-table mat-elevation-z8"
    matSortActive="createdDate"
    matSortDirection="desc"
    [ngClass]="{ hidden: !dataSource.data.length }"
  >
    <ng-container matColumnDef="activationType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Activation Type</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.activationType === 'DIG' ? 'MEDIA' : element.activationType
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="urlId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SEB Campaign Id</th>
      <td mat-cell *matCellDef="let element">{{ element.urlId }}</td>
    </ng-container>

    <ng-container matColumnDef="urlMediumName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        SEB Campaign Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.urlMediumName }}</td>
    </ng-container>

    <ng-container matColumnDef="used">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="SEB Campaign Name has been used in an activation tool (Google Adword, Facebook,...)"
      >
        Used
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="state-chip" [ngClass]="{ used: element.used }"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="copyCampaignUrls">
      <th mat-header-cell *matHeaderCellDef>Copy SEB Campaign Names</th>
      <td mat-cell *matCellDef="let element">
        <button
          [ngClass]="{ empty: !element.urlShortName }"
          mat-icon-button
          class="light"
          (click)="onCopyToClipboard(element.urlShortName)"
          matTooltip= "{{!!element.urlShortName ? 'Copy short campaign name' : 'No short campaign name'}}"
        >
          <mat-icon>file_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          class="medium"
          (click)="onCopyToClipboard(element.urlMediumName)"
          matTooltip="Copy medium campaign name"
        >
          <mat-icon>file_copy</mat-icon>
        </button>

        <button
          mat-icon-button
          color="primary"
          (click)="onCopyToClipboard(element.urlFullName)"
          matTooltip="Copy long campaign name"
        >
          <mat-icon>file_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
      <td mat-cell *matCellDef="let element">{{ element.createdDate }}</td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" [matMenuTriggerFor]="options">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #options="matMenu">
          <button mat-menu-item (click)="onDisplay(element)">Display</button>
          <button mat-menu-item (click)="onModify(element)">Modify</button>
          <button mat-menu-item (click)="onDuplicate(element)">
            Duplicate
          </button>
          <button
            mat-menu-item
            (click)="onDelete(element)"
            [disabled]="element.used"
          >
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div *ngIf="!dataSource.data.length">
    <div *ngIf="!showNoResultsMsg" class="default-message">
      <p>
        To display campaigns, please click on "My Campaigns" (based on your
        credentials) <strong>or</strong> select your filter(s) and click on
        search.
      </p>
    </div>

    <div *ngIf="showNoResultsMsg" class="no-results">
      Sorry, there is no result for your search...
    </div>
  </div>

  <div class="footer">
    <mat-paginator
      [pageSizeOptions]="[
        6,
        50,
        dataSource.data.length > 500 ? 500 : dataSource.data.length
      ]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<app-loader *ngIf="isLoading"></app-loader>
