import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { StorageKey } from 'src/app/core/enums';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn(): boolean {
    const token = localStorage.getItem(StorageKey.USER_TOKEN);
    const payload = token ? atob(token.split('.')[1]) : undefined;
    const parsePayload = payload ? JSON.parse(payload) : null;

    return !!token && parsePayload.exp > Date.now() / 1000
  }

  public async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession()
  }

}
