export interface ICampaign {
    activation_type: string;
    brand: string;
    buying_method: string;
    campaign_type: string;
    campaign_link_redirection: string;
    channel: string;
    communication: string;
    consumer_type: string;
    created_date: string;
    cycle_or_journey: string;
    duration_type: string;
    engine: string;
    event_type: string;
    family: string;
    format: string;
    funding_source: string;
    funnel: string;
    id: string;
    language: string;
    market: string;
    media: string;
    objective: string;
    optional: string;
    owner?: string
    product_line: string;
    stage: string;
    start_month: string;
    sub_family: string;
    updated_date?: string;
    url_full?: string;
    url_short?: string;
    url_state?: string;
    url_xSmall?: string;
}

export interface IfilterValues {
    campaign_id: string
    product_line: string
    market: string
    brand: string
}

export interface ICampaignSearchParams {
    filter: IfilterValues;
    markets: string;
}

export interface ICampaignEngine {
    engine: string;
    engineFree: any;
}