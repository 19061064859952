import { Injectable } from '@angular/core'
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { retry, catchError } from 'rxjs/operators'
import { UserService } from '@core/services/user.service'
import { Router } from '@angular/router'

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(private readonly userService: UserService, private readonly router: Router) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.router.navigate(['signin']);
                    return throwError(error);
                } else {
                    return throwError(error)
                }
            }),
        )
    }
}
