import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignListPageComponent } from './campaign-list-page.component';
import { CampaignListPageRoutingModule } from './campaign-list-page-routing.module';
import { CampaignsModule } from '@features/campaigns/campaigns.module';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [CampaignListPageComponent],
  imports: [
    CommonModule,
    CampaignListPageRoutingModule,
    CampaignsModule,
    SharedModule
  ]
})
export class CampaignListModule { }
