<div [formGroupName]="crmFormName" id="crmForm">
  <div class="row">
    <section>
      <h2>Activation Objectives</h2>

      <!-- objective -->
      <mat-form-field appearance="outline">
        <mat-label>Objective</mat-label>
        <mat-select formControlName="objective">
          <mat-option *ngFor="let objective of objectives" [value]="objective.value">
            {{ objective.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- Funnel -->
      <mat-form-field appearance="outline">
        <mat-label>Funnel phase</mat-label>
        <mat-select formControlName="funnel">
          <mat-option *ngFor="let funnel of funnels" [value]="funnel.value">{{ funnel.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- Campaign link redirection -->
      <mat-form-field appearance="outline">
        <mat-label>Campaign link redirection</mat-label>
        <mat-select formControlName="campaignLinkRedirection">
          <mat-option *ngFor="let link of campaignLinkRedirections" [value]="link.value">{{ link.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- Campaign link redirection -> funding source -->
      <mat-form-field appearance="outline">
        <mat-label>Funding source</mat-label>
        <mat-select formControlName="fundingSource">
          <mat-option *ngFor="let source of fundingSources" [value]="source.value">{{ source.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- Consumer Type -->
      <mat-form-field appearance="outline">
        <mat-label>Consumer type</mat-label>
        <mat-select formControlName="consumerType">
          <mat-option *ngFor="let type of consumerTypes" [value]="type.value">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>
    </section>

    <section>
      <h2>Activation Details</h2>

      <!-- stage -->
      <mat-form-field appearance="outline">
        <mat-label>Stage</mat-label>
        <mat-select formControlName="stage">
          <mat-option *ngFor="let stage of stages" [value]="stage.value">{{
            stage.props.name
            }}</mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- stage -> usecase -->
      <mat-form-field appearance="outline">
        <mat-label>Use case</mat-label>
        <mat-select formControlName="cycleOrJourney">
          <mat-option *ngFor="let cj of cyclesJourneys" [value]="cj.value">{{
            cj.name
            }}</mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- stage -> usecase -> communication -->
      <mat-form-field appearance="outline">
        <mat-label>Communication</mat-label>
        <mat-select formControlName="communication">
          <mat-option *ngFor="let comm of communications" [value]="comm.value">
            {{ comm.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>

      <!-- type of campaign -->
      <mat-form-field appearance="outline">
        <mat-label>Type of Campaign</mat-label>
        <mat-select formControlName="campaignType">
          <mat-option *ngFor="let type of campaignTypes" [value]="type.value">{{
            type.name
            }}</mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>
    </section>

    <section class="last-field">
      <!-- channel -->
      <mat-form-field appearance="outline">
        <mat-label>Channel</mat-label>
        <mat-select formControlName="channel">
          <mat-option *ngFor="let channel of channels" [value]="channel.value">{{ channel.name }}</mat-option>
        </mat-select>
        <mat-error>
          <mat-icon class="icon-error">error</mat-icon>
          {{ errors.required }}
        </mat-error>
      </mat-form-field>
    </section>
  </div>
</div>