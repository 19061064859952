import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { SigninComponent } from './components/signin/signin.component';



@NgModule({
  declarations: [
    SigninComponent
  ],
  imports: [
    CommonModule,
    AmplifyAuthenticatorModule,

  ],
  exports: [
    SigninComponent
  ]
})
export class AuthModule { }
