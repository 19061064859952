import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UserService } from '@core/services/user.service'
import { Observable } from 'rxjs'

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private readonly userService: UserService) {

  }
  intercept(
    HttpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let authReq
    const userToken = this.userService.userToken
    authReq = HttpRequest.clone({
      headers: HttpRequest.headers.set('Authorization', userToken),
    })
    return next.handle(authReq)
  }
}
