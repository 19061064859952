import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CampaignListComponent } from '@features/campaigns/components/campaign-list/campaign-list.component';
import { MaterialModule } from 'src/app/material.module';
import { CampaignUrlDialogComponent } from './components/campaign-url-dialog/campaign-url-dialog.component';
import { CampaignService } from './services/campaign.service';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignFormService } from './services/campaign-form.service';
import { SharedModule } from '@shared/shared.module';
import { CampaignDeleteDialogComponent } from './components/campaign-delete-dialog/campaign-delete-dialog.component';
import { CrmFormComponent } from './components/campaign-form/crm-form/crm-form.component';
import { MediaFormComponent } from './components/campaign-form/media-form/media-form.component';
import { ProductFormComponent } from './components/campaign-form/product-form/product-form.component';

@NgModule({
  declarations: [
    CampaignListComponent,
    CampaignUrlDialogComponent,
    CampaignFormComponent,
    CampaignDeleteDialogComponent,
    CrmFormComponent,
    MediaFormComponent,
    ProductFormComponent,
  ],
  imports: [
    CommonModule, 
    SharedModule,
    MaterialModule, 
    FormsModule, 
    ReactiveFormsModule,
  ],
  providers: [
    CampaignService, 
    CampaignFormService
  ],
  exports: [
    FormsModule, 
    ReactiveFormsModule,
    CampaignUrlDialogComponent, 
    CampaignFormComponent,
    CampaignListComponent,
    CampaignDeleteDialogComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CampaignsModule {}
