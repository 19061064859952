import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms'
import { ActivationType, FormName } from '@core/enums'
import { IRessource } from '@core/models'
import { ResourceService } from '@core/services/resource.service'
import { ICampaign } from '@features/campaigns/models/campaign.model'
import { CampaignFormService } from '@features/campaigns/services/campaign-form.service'
import { takeUntil } from 'rxjs/operators'
import { BaseComponent } from 'src/app/core/base.component'
import { MediaFormControl } from 'src/app/features/campaigns/models'
import { FormMode, ListSortMode } from 'src/app/core/enums'

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class MediaFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() errors: any

  @Input() mode: string

  @Input() sourceCampaign?: ICampaign

  public mediaFormName = FormName.MEDIA

  private campaignForm!: UntypedFormGroup

  private mediaForm!: UntypedFormGroup

  public mediaData!: any

  public buyingMethods: IRessource[]
  public campaignLinkRedirections: IRessource[]
  public campaignTypes: IRessource[]
  public channels: IRessource[]
  public engines: IRessource[]
  public formats: IRessource[]
  public fundingSources: IRessource[]
  public funnels: IRessource[]

  constructor(
    private readonly parent: FormGroupDirective,
    private readonly fb: UntypedFormBuilder,
    private readonly resourceService: ResourceService,
    private readonly campaignFormService: CampaignFormService,
  ) {
    super()
    super.ngOnDestroy()
  }

  ngOnInit(): void {
    this.initForm()
    this.initData()
    this.handleMediaFormChanges()
    this.populateMediaForm()
    this.resolveCrmFormState()
  }

  ngOnDestroy(): void {
    this.removeMediaForm()
  }

  // PRIVATE METHODS

  private initForm(): void {
    this.campaignForm = this.parent.form
    this.campaignForm.addControl(
      FormName.MEDIA,
      this.fb.group({
        channel: ['', [Validators.required]],
        format: [{ value: '', disabled: true }, [Validators.required]],
        funnel: ['' , [Validators.required]],
        campaignLinkRedirection: ['', [Validators.required]],
        fundingSource: [{ value: '', disabled: true }, [Validators.required]],
        engine: [{ value: '', disabled: true }, [Validators.required]],
        engineFree: [{ value: '', disabled: true }],
        buyingMethod: [{ value: '', disabled: true }, [Validators.required]],
        campaignType: [{ value: '', disabled: true }, [Validators.required]],
      }),
    )
    this.mediaForm = this.campaignForm.controls[FormName.MEDIA] as UntypedFormGroup
  }

  private initData(): void {
    this.mediaData = this.resourceService.getResourcesByForm(FormName.MEDIA)
    this.channels = this.mediaData.channels
    this.funnels = this.mediaData.funnels
    this.campaignLinkRedirections = this.mediaData.campaignLinkRedirections
  }

  private handleMediaFormChanges(): void {
    // channel changes
    this.mediaForm.controls.channel.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleChannelChanges(value)
      })

    // engine changes
    this.mediaForm.controls.engine.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleEngineChanges(value)
      })

    // free engine changes
    this.mediaForm.controls.engineFree.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleFreeEngineChanges(value)
      })

    // campaign link redirection
    this.mediaForm.controls.campaignLinkRedirection.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleCampaignLinkRedirectionChanges(value)
      })
  }

  private handleChannelChanges(value: string): void {
    // reset dependent fields
    this.resetChannelDependentFields()
    // // resolve dependencies
    // format
    this.formats = this.campaignFormService.resolveDependencies(
      value,
      this.mediaData.formats,
    )
    // engine
    this.engines = this.campaignFormService.resolveDependencies(
      value,
      this.mediaData.engines,
      ListSortMode.PER_VALUE,
    )
    // buying method

    this.buyingMethods = this.campaignFormService.resolveDependencies(
      value,
      this.mediaData.buyingMethods,
    )
    // type of campaign

    this.campaignTypes = this.campaignFormService.resolveDependencies(
      value,
      this.mediaData.campaignTypes,
    )

    // Resolve fields state
    this.campaignFormService.resolveFieldState(
      MediaFormControl.FORMAT,
      this.mediaForm,
      this.formats,
    )
    this.campaignFormService.resolveFieldState(
      MediaFormControl.ENGINE,
      this.mediaForm,
      this.engines,
    )
    this.campaignFormService.resolveFieldState(
      MediaFormControl.BUYING_METHOD,
      this.mediaForm,
      this.buyingMethods,
    )
    this.campaignFormService.resolveFieldState(
      MediaFormControl.CAMPAIGN_TYPE,
      this.mediaForm,
      this.campaignTypes,
    )
  }

  private handleEngineChanges(value: string) {
    if (value === MediaFormControl.ENGINE_FREE) {
      // enable engine free field
      this.mediaForm.controls.engineFree.enable()
      this.campaignFormService.setRequiredValidators(
        this.mediaForm,
        MediaFormControl.ENGINE_FREE,
      )
    } else {
      this.mediaForm.controls.engineFree.reset()
      this.mediaForm.controls.engineFree.disable()
    }
  }

  private handleFreeEngineChanges(value: string) {
    if (!!value) {
      const forbiddenCharacters = /[^a-zA-Z0-9]+/g
      if (forbiddenCharacters.test(value)) {
        this.mediaForm.controls.engineFree.setValue(
          value.replace(forbiddenCharacters, ''),
        )
      }
    }
  }

  private resetChannelDependentFields(): void {
    this.mediaForm.controls.format.reset()
    this.mediaForm.controls.engine.reset()
    this.mediaForm.controls.buyingMethod.reset()
    this.mediaForm.controls.campaignType.reset()
  }

  private handleCampaignLinkRedirectionChanges(value: string): void {
    this.mediaForm.controls.fundingSource.reset()

    this.fundingSources = this.campaignFormService.resolveDependencies(
      value,
      this.mediaData.fundingSources,
    )

    // Resolve dependent field state
    this.campaignFormService.resolveFieldState(
      MediaFormControl.FUNDING_SOURCE,
      this.mediaForm,
      this.fundingSources,
    )
  }

  private removeMediaForm(): void {
    this.campaignForm.removeControl(FormName.MEDIA)
  }

  private populateMediaForm(): void {
    if (this.mode !== FormMode.CREATE) {
      const enginesResources = this.resourceService.getResources().engines
      this.campaignFormService.populateMediaForm(
        this.mediaForm,
        this.sourceCampaign,
        enginesResources,
      )
    }
  }

  private resolveCrmFormState(): void {
    if (this.mode === FormMode.DISPLAY) {
      this.mediaForm.disable({ emitEvent: false })
    }
  }
}
