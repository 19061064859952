import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { HeaderComponent } from './header/header.component';
import { GoldenRulesDialogComponent } from './golden-rules-dialog/golden-rules-dialog.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [HeaderComponent, GoldenRulesDialogComponent, LoaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    HeaderComponent,
    GoldenRulesDialogComponent,
    LoaderComponent
  ]
})
export class SharedModule { }
