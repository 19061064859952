import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { CampaignsModule } from '@features/campaigns/campaigns.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { CampaignCreatePageModule } from './pages/campaign-create-page/campaign-create-page.module';
import { CampaignListModule } from './pages/campaign-list-page/campaign-list-page.module';
import { CampaignUpdateModule } from './pages/campaign-update-page/campaign-update-page.module';
import { CampaignDuplicateModule } from './pages/campaign-duplicate-page/campaign-duplicate-page.module';
import { SigninPageModule } from './pages/signin-page/signin-page.module';
import { SharedModule } from './shared/shared.module';

import { AmplifyAuthenticatorModule  } from '@aws-amplify/ui-angular';
import { AuthModule } from '@features/auth/auth.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    MaterialModule,
    HttpClientModule,
    NgSelectModule,
    SharedModule,
    AppRoutingModule,
    ClipboardModule,
    CampaignsModule,
    AuthModule,
    CampaignListModule,
    CampaignCreatePageModule,
    CampaignUpdateModule,
    CampaignDuplicateModule,
    SigninPageModule,
    AmplifyAuthenticatorModule ,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
