import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms'
import { ActivationType, FormName } from '@core/enums'
import { ILeadRessource } from '@core/models/lead-ressource.model'
import { IRessource } from '@core/models/ressource.model'
import { ResourceService } from '@core/services/resource.service'
import { ICampaign } from '@features/campaigns/models/campaign.model'
import { CampaignFormService } from '@features/campaigns/services/campaign-form.service'
import { FormMode, ListSortMode } from 'src/app/core/enums'
import { takeUntil } from 'rxjs/operators'
import { BaseComponent } from 'src/app/core/base.component'
import { CrmFormControl } from 'src/app/features/campaigns/models'

@Component({
  selector: 'app-crm-form',
  templateUrl: './crm-form.component.html',
  styleUrls: ['./crm-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CrmFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() errors: any

  @Input() mode: string

  @Input() sourceCampaign?: ICampaign

  public crmFormName = FormName.CRM

  private campaignForm!: UntypedFormGroup

  private crmForm!: UntypedFormGroup

  public CRMData!: any

  public consumerTypes: IRessource[]
  public stages: ILeadRessource[]
  public communications: IRessource[]
  public cyclesJourneys: IRessource[]
  public objectives: IRessource[]
  public funnels: IRessource[]
  public campaignTypes: IRessource[]
  public campaignLinkRedirections: IRessource[]
  public fundingSources: IRessource[]
  public channels: IRessource[]

  constructor(
    private readonly parent: FormGroupDirective,
    private readonly fb: UntypedFormBuilder,
    private readonly resourceService: ResourceService,
    private readonly campaignFormService: CampaignFormService,
  ) {
    super()
    super.ngOnDestroy()
  }

  ngOnInit(): void {
    this.initForm()
    this.initData()
    this.handleFormChanges()
    this.populateCrmForm()
    this.resolveCrmFormState()
  }

  ngOnDestroy(): void {
    this.removeCrmForm()
  }

  // PRIVATE METHODS

  private initForm(): void {
    this.campaignForm = this.parent.form
    this.campaignForm.addControl(
      FormName.CRM,
      this.fb.group({
        consumerType: ['', [Validators.required]],
        objective: ['', [Validators.required]],
        funnel: ['', [Validators.required]],
        campaignLinkRedirection: ['', [Validators.required]],
        fundingSource: [{ value: '', disabled: true }, [Validators.required]],
        stage: ['', [Validators.required]],
        cycleOrJourney: [{ value: '', disabled: true }, [Validators.required]],
        communication: [{ value: '', disabled: true }, [Validators.required]],
        channel: [{ value: '', disabled: true }, [Validators.required]],
        campaignType: [{ value: '', disabled: true }, [Validators.required]],
      }),
    )
    this.crmForm = this.campaignForm.controls[FormName.CRM] as UntypedFormGroup
  }

  private initData(): void {
    this.CRMData = this.resourceService.getResourcesByForm(FormName.CRM)
    this.consumerTypes = this.CRMData.consumerTypes
    this.stages = this.CRMData.stages
    this.objectives = this.CRMData.objectives
    this.funnels = this.CRMData.funnels
    this.channels = this.CRMData.channels
    this.campaignLinkRedirections = this.CRMData.campaignLinkRedirections
  }

  private handleFormChanges(): void {
    this.crmForm.controls.stage.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleStageChanges(value)
      })

    this.crmForm.controls.cycleOrJourney.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleCycleJourneyChanges(value)
      })

    this.crmForm.controls.communication.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleCommunicationsChanges(value)
      })

    // campaign link redirection
    this.crmForm.controls.campaignLinkRedirection.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleCampaignLinkRedirectionChanges(value)
      })

    this.campaignForm.controls.eventType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleEventTypeChange(value)
      })
  }

  private handleEventTypeChange(value: string): void {
    console.log("event type change", value)
    this.handleStageChanges(this.crmForm.controls.stage.value)
  }

  private handleStageChanges(value: string): void {
    // reset dependent fields
    console.log("handle stgae change value: ", value)
    this.crmForm.controls.cycleOrJourney.reset()
    this.crmForm.controls.communication.reset()
    this.crmForm.controls.campaignType.reset()

    this.cyclesJourneys = this.campaignFormService.resolveDependencies(
      value,
      this.CRMData.cyclesJourneys,
      ListSortMode.PER_ORIGIN_ORDER,
    )
    this.campaignFormService.resolveFieldState(
      CrmFormControl.CYCLE_OR_JOURNEY,
      this.crmForm,
      this.cyclesJourneys,
    )
      console.log("cycle journeys in stage change", this.cyclesJourneys)
    if (this.campaignForm.controls.eventType.value) {
      console.log("eventType change in stage change", this.campaignForm.controls.eventType.value)
      this.handleCyclesJourneysValue(this.campaignForm.controls.eventType.value)
    }
  }

  private handleCycleJourneyChanges(value: string): void {
    this.crmForm.controls.communication.reset()

    this.communications = this.campaignFormService.resolveDependencies(
      value,
      this.CRMData.communications,
      ListSortMode.PER_ORIGIN_ORDER,
    )
    this.campaignFormService.resolveFieldState(
      CrmFormControl.COMMUNICATION,
      this.crmForm,
      this.communications,
    )
  }

  private handleCyclesJourneysValue(value: string): void {

    if (value !== ".1NSE") {
      console.log("HEY Thematic Events", this.cyclesJourneys)
      this.cyclesJourneys?.forEach((element, index) => {
        if (element.value === "NEWLT") {
          this.cyclesJourneys.splice(index, 1)
        }
      })
    }
    else {
      console.log("HEY NO SPECIFIC EVENT", this.cyclesJourneys)
      this.cyclesJourneys?.forEach((element, index) => {
        if (element.value === "THEEV") {
          this.cyclesJourneys.splice(index, 1)
        }
      })
    }
  }

  private handleCommunicationsChanges(value: string): void {
    this.crmForm.controls.campaignType.reset()

    this.campaignTypes = this.campaignFormService.resolveDependencies(
      value,
      this.CRMData.campaignTypes,
    )
    this.campaignFormService.resolveFieldState(
      CrmFormControl.CAMPAIGN_TYPE,
      this.crmForm,
      this.campaignTypes,
    )

    if (value) {
      this.crmForm.controls.channel.enable()
    }
  }

  private handleCampaignLinkRedirectionChanges(value: string): void {
    this.crmForm.controls.fundingSource.reset()

    this.fundingSources = this.campaignFormService.resolveDependencies(
      value,
      this.CRMData.fundingSources,
    )
    // Resolve dependent field state
    this.campaignFormService.resolveFieldState(
      CrmFormControl.FUNDING_SOURCE,
      this.crmForm,
      this.fundingSources,
    )
  }

  private removeCrmForm(): void {
    this.campaignForm.removeControl(FormName.CRM)
  }

  private populateCrmForm(): void {
    if (this.mode !== FormMode.CREATE) {
      this.campaignFormService.populateCrmForm(
        this.crmForm,
        this.sourceCampaign,
      )
    }
  }

  private resolveCrmFormState(): void {
    if (this.mode === FormMode.DISPLAY) {
      this.crmForm.disable({ emitEvent: false })
    }
  }
}
