import { Injectable } from '@angular/core';

import { Resources } from '@core/resources';

import { FormName } from '@core/enums/form-names.enum';
import { ILeadData, ILeadRessource, IResourcesList, IRessource, ISourceData } from '@core/models';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})

export class ResourceService {
  constructor(
    private readonly userService: UserService
  ) {}

  /**
   * 
   * @returns data from json resources
   */
  getResources(): IResourcesList {
    const resources = {
      brands: Resources.brands,
      buyingMethods: Resources.buyingMethods,
      campaignLinkRedirections: Resources.campaignLinkRedirections,
      campaignTypes: Resources.campaignTypes,
      channels: Resources.channels,
      cyclesJourneys: Resources.cyclesJourneys,
      engines: Resources.engines,
      events: Resources.eventTypes,
      families: Resources.productFamily,
      formats: Resources.formats,
      fundingSources: Resources.fundingSources,
      funnels: Resources.funnels,
      languages: Resources.languages,
      sharedEvents: Resources.shared_eventTypes,
      subFamilies: Resources.productSubFamily,
    };

    return resources;
  }

  getResourcesByForm(form: FormName.CRM | FormName.MEDIA | FormName.PRODUCT) {
    switch (form) {
      case FormName.CRM : 
      return this.getCRMData();

      case FormName.MEDIA : 
      return this.getMediaData();

      case FormName.PRODUCT :
        return this.getProductData();
    }
  }

  /**
   * @returns builded lead data (i.e with no parent data)
   */
  resolveLeadData(): ILeadData {
    return {
      markets: this.filterMarketsByLoggedUser(this.buildLeadData(this.getLeadResources().markets)),
      productLines: this.buildLeadData(this.getLeadResources().productLines),
      durations: this.buildLeadData(this.getLeadResources().durations),
      objectives: this.buildLeadData(this.getLeadResources().objectives),
      activationTypes: this.buildLeadData(this.getLeadResources().activationTypes),
      consumerTypes: this.buildLeadData(this.getLeadResources().consumerTypes),
      medias: this.buildLeadData(this.getLeadResources().medias),
      iterations: this.buildLeadData(this.getLeadResources().iterations),
    };
  }

  /**
   * Filter markets data by logged user
   * @param marketsData 
   * @returns filtered array of type ILeadResource
   */
  private filterMarketsByLoggedUser(marketsData: ILeadRessource[]): ILeadRessource[]{
    const userMarkets = this.userService.getUserMarket().split(' ');
    let filteredMarkets: ILeadRessource[][] = [];
    userMarkets.map((market) => {
      filteredMarkets.push(this.filterByMarket(marketsData, market))
    });
    return filteredMarkets.flat()
  }

  /**
   * 
   * @param arr ILeadRessource array
   * @param value logged user custom attribute markets
   * @returns 
   */
  private filterByMarket(arr: ILeadRessource[], value: string): ILeadRessource[] {
    if (value === "*") {
      return arr;
    } else {
      return arr.filter(el => el.value === value)
    }
  }

  /**
   * 
   * @returns imported lead data from json resources
   */
  private getLeadResources(): any {
    return {
      markets: Resources.markets,
      productLines: Resources.productLines,
      durations: Resources.durationTypes,
      objectives: Resources.objectives,
      activationTypes: Resources.activationTypes,
      consumerTypes: Resources.consumerTypes,
      medias: Resources.medias,
      iterations: Resources.iterations,
      stages: Resources.stages,
      cyclesJourneys: Resources.cyclesJourneys,
    }
  }

  /**
   * Build lead data with dependencies
   * @param leadData json ressource
   * @returns ILeadRessource array [{value: 'value', props: {name: 'name, ...}}, ...]
   */
  private buildLeadData(leadData: any): ILeadRessource[] {
    let result = [];
    for (const [key, value] of Object.entries(leadData)) {
      result.push({ props: value, value: key });
    }
    return result;
  }

  private buildStandAloneResource(sourceData: ISourceData): IRessource[] {
    let result = [];
    for (const [key, value] of Object.entries(sourceData)) {
      result.push({name: value.name, value: key })
    }
    return result;
  }

  private getCRMData() {
    return {
      campaignLinkRedirections: this.buildStandAloneResource(Resources.campaignLinkRedirections),
      campaignTypes: Resources.crmCampainTypes,
      channels: this.buildStandAloneResource(Resources.channels.CRM),
      communications: Resources.communications,
      consumerTypes: this.buildStandAloneResource(Resources.consumerTypes),
      cyclesJourneys: Resources.cyclesJourneys,
      fundingSources: Resources.fundingSources,
      funnels: this.buildStandAloneResource(Resources.funnels.CRM),
      objectives: this.buildStandAloneResource(Resources.objectives),
      stages: this.buildLeadData(this.getLeadResources().stages),
    }
  }

  private getMediaData() {
    return {
      buyingMethods: Resources.buyingMethods,
      campaignLinkRedirections: this.buildStandAloneResource(Resources.campaignLinkRedirections),
      campaignTypes: Resources.campaignTypes,
      channels: this.buildStandAloneResource(Resources.channels.DIG),
      engines: Resources.engines,
      formats: Resources.formats,
      fundingSources: Resources.fundingSources,
      funnels: this.buildStandAloneResource(Resources.funnels.DIG),
    }
  }

  private getProductData() {
    return {
      productLines: this.buildStandAloneResource(Resources.productLines),
      families: Resources.productFamily,
      subFamilies: Resources.productSubFamily
    }
  }

}
