import { Injectable } from '@angular/core'
import { UntypedFormGroup, Validators } from '@angular/forms'
import { ArrayHelper } from '@core/helpers/array.helper'
import { RandomIdGenerator } from '@core/helpers/randomId.generator'
import { IRessource, IRessourceName } from '@core/models'
import { FormMode, FormName, ActivationType } from '@core/enums'
import dayjs from "dayjs"
import { ListSortMode } from 'src/app/core/enums'
import {
  ICampaign,
  ICampaignForm,
  ICampaignEngine,
  IMediaForm,
  ICrmForm,
} from 'src/app/features/campaigns/models'

@Injectable({
  providedIn: 'root',
})
export class CampaignFormService {
  constructor(
    private readonly idGenerator: RandomIdGenerator,
    private readonly arrayHelper: ArrayHelper,
  ) {}

  /**
   * Resolve 'media' hidden field value
   * @param formValue
   * @returns value type string
   */
  private resolveMedia(formValue: ICampaignForm): string {
    const activationType = formValue.activationType
    const channel =
      formValue[FormName.CRM]?.channel ?? formValue[FormName.MEDIA].channel
    if (activationType === ActivationType.CRM) {
      return ActivationType.CRM
    } else if (channel === 'OGSOC' || channel === 'INFLU') {
      return 'SIN'
    } else {
      return ActivationType.MEDIA
    }
  }

  /**
   * Resolve field state (enabled or disabled) depending on data populated or not
   * Resolve value if field is populated by only one item
   * @param field
   * @param form
   * @param data
   */
  public resolveFieldState(
    field: string,
    form: UntypedFormGroup,
    data: IRessource[],
  ): void {
    if (data && !!data.length) {
      form.get(field).enable()
      this.resolveOneValueField(field, form, data)
    } else {
      form.get(field).disable()
    }
  }

  /**
   * Set field value if data contains only one item
   * @param field field name
   * @param form
   * @param data populated data
   */
  private resolveOneValueField(
    field: string,
    form: UntypedFormGroup,
    data: IRessource[],
  ): void {
    if (data.length == 1) {
      form.get(field).setValue(data[0].value)
    }
  }

  /**
   * Set field to required
   * @param form used form
   * @param field form field to affect
   */
  public setRequiredValidators(form: UntypedFormGroup, field: string): void {
    form.get(field).setValidators([Validators.required])
    form.get(field).updateValueAndValidity({ emitEvent: false })
  }

  /**
   * Clear field required validator
   * @param form used form
   * @param field form field to affect
   */
  public clearRequiredValidators(form: UntypedFormGroup, field: string): void {
    form.get(field).clearValidators()
    form.get(field).updateValueAndValidity({ emitEvent: false })
  }

  /**
   *
   * @param parentValue parent input value type string
   * @param child json object
   * @param {ListSortMode} sortMode (optional) mode to sort results
   * @returns {IRessource[]} ([{name: "name1", value: "value1"}, {name: "name2", value: "value2"}, ...])
   */
  public resolveDependencies(
    parentValue: string,
    child: any,
    sortMode?: ListSortMode,
  ): IRessource[] {
    let values: string[]
    let names: IRessourceName[]
    let result: IRessource[]

    for (const [key, value] of Object.entries(child)) {
      if (key == parentValue) {
        names = Object.values(value) // [{name1: ''}, {name2: ''}]
        values = Object.keys(value) // ['name1', 'name2]
      }
    }
    result = values?.map((val, index) => {
      return {
        name: names[index].name,
        value: val,
      }
    })

    switch (sortMode) {
      case ListSortMode.PER_VALUE:
        return this.arrayHelper.sortResourcesArrayByValue(result)

      case ListSortMode.PER_ORIGIN_ORDER:
        return result

      default:
        return this.arrayHelper.sortResourcesArray(result)
    }
  }

  /**
   * Resolve engine free value and engine value
   * @param enginesData engines json
   * @param value engine value to check
   * @returns engine value
   */
  private resolveEngineValue(enginesData, value): ICampaignEngine {
    let engineValues = []
    let array = []
    for (const [key, value] of Object.entries(enginesData)) {
      engineValues.push(value)
    }
    engineValues.map((item) => {
      array.push(Object.keys(item))
    })
    array = array.flat()

    array = Array.from(new Set(array))
    if (!!array.find((el) => el == value)) {
      return { engine: value, engineFree: undefined }
    } else {
      return { engine: 'engineFree', engineFree: value }
    }
  }

  /**
   * Populate campaign form from source campaign
   * Display source campaign values on display, modify and update mode
   * @param form
   * @param sourceCampaign
   */
  public populateCampaignForm(
    form: UntypedFormGroup,
    sourceCampaign: ICampaign,
  ): void {
    const startMonth = dayjs(sourceCampaign.start_month, 'YYYY-MM').toDate()
    form.patchValue({
      market: sourceCampaign.market,
      language: sourceCampaign.language,
      brand: sourceCampaign.brand,
      productLine: sourceCampaign.product_line,
      family: sourceCampaign.family,
      subFamily: sourceCampaign.sub_family,
      durationType: sourceCampaign.duration_type,
      startMonth,
      eventType: sourceCampaign.event_type,
      optional: sourceCampaign.optional,
      activationType: sourceCampaign.activation_type,
    })
  }

  /**
   * Resolve campaignUrl values by mode (update or create)
   * some values must not change on update mode (ex id, created date)
   * @param formValue
   * @param mode
   * @param sourceCampaign
   * @returns campaignUrl values (type ICampaign)
   */
  public resolveUrlCampaignValuesByMode(
    formValue: ICampaignForm,
    mode: FormMode,
    sourceCampaign?: ICampaign,
  ): ICampaign {
    let id, updated_date, created_date, url_state, start_month
    const NotAttributed = 'NA'
    const product = formValue.productType
    const engine = this.setEngineValue(formValue.activationType, formValue)
    const media = this.resolveMedia(formValue)

    const activationTypeValues = this.resolveValuesByActivationType(
      formValue.activationType,
      formValue,
    )

    if (mode === FormMode.UPDATE) {
      id = sourceCampaign.id
      start_month = formValue.startMonth
        ? dayjs(formValue.startMonth).format('YYYY-MM').toString()
        : NotAttributed
      updated_date = dayjs().format('YYYY-MM-DD').toString()
      created_date = sourceCampaign.created_date
      url_state = sourceCampaign.url_state
    } else {
      id = this.idGenerator.generateId()
      start_month = formValue.startMonth
        ? dayjs(formValue.startMonth).format('YYYY-MM').toString()
        : NotAttributed
      created_date = dayjs().format('YYYY-MM-DD').toString()
      url_state = 'UNUSED'
    }
    return {
      id: String(id),
      market: formValue.market,
      language: formValue.language || NotAttributed,
      brand: formValue.brand,
      product_line: product.productLine || 'NSP',
      family: product.family || '.NSF',
      sub_family: product.subFamily || '.NSSFP',
      funnel: activationTypeValues.funnel || NotAttributed,
      funding_source: activationTypeValues.funding_source || NotAttributed,
      channel: activationTypeValues.channel,
      format: activationTypeValues.format || NotAttributed,
      duration_type: formValue.durationType || NotAttributed,
      start_month,
      engine,
      buying_method: activationTypeValues.buying_method || NotAttributed,
      campaign_type: activationTypeValues.campaign_type || NotAttributed,
      campaign_link_redirection:
        activationTypeValues.campaign_link_redirection || NotAttributed,
      optional: formValue.optional || NotAttributed,
      event_type: formValue.eventType,
      consumer_type: activationTypeValues.consumer_type || NotAttributed,
      cycle_or_journey: activationTypeValues.cycle_or_journey || NotAttributed,
      media,
      activation_type: formValue.activationType,
      objective: activationTypeValues.objective || NotAttributed,
      url_state,
      created_date,
      updated_date,
      communication: activationTypeValues.communication || NotAttributed,
      stage: activationTypeValues.stage || NotAttributed,
    }
  }

  public populateMediaForm(
    form: UntypedFormGroup,
    sourceCampaign: ICampaign,
    enginesResources: any,
  ): void {
    let engine = this.resolveEngineValue(
      enginesResources,
      sourceCampaign.engine,
    )
    const hasActivationTypeChanged = sourceCampaign.activation_type !== ActivationType.MEDIA;

    const mediaFormValues: IMediaForm = {
      channel: hasActivationTypeChanged ? undefined : sourceCampaign.channel,
      format: sourceCampaign.format,
      funnel:  hasActivationTypeChanged ? undefined : sourceCampaign.funnel,
      campaignLinkRedirection: sourceCampaign.campaign_link_redirection,
      fundingSource: sourceCampaign.funding_source,
      engine: engine.engine,
      engineFree: engine.engineFree,
      buyingMethod: sourceCampaign.buying_method,
      campaignType:  hasActivationTypeChanged ? undefined : sourceCampaign.campaign_type,
    }

    form.patchValue(mediaFormValues)
  }

  public populateCrmForm(form: UntypedFormGroup, sourceCampaign: ICampaign): void {
    const hasActivationTypeChanged = sourceCampaign.activation_type !== ActivationType.CRM;

    // Warning attribute orders important to follow changes
    const crmFormValues: ICrmForm = {
      objective: sourceCampaign.objective,
      funnel:  hasActivationTypeChanged ? undefined : sourceCampaign.funnel,
      campaignLinkRedirection: sourceCampaign.campaign_link_redirection,
      fundingSource: sourceCampaign.funding_source,

      consumerType: sourceCampaign.consumer_type,
      stage: sourceCampaign.stage,
      cycleOrJourney: sourceCampaign.cycle_or_journey,
      communication: sourceCampaign.communication,
      campaignType:  hasActivationTypeChanged ? undefined : sourceCampaign.campaign_type,
      channel: hasActivationTypeChanged ? undefined : sourceCampaign.channel,
    }

    form.patchValue(crmFormValues)
  }

  public populateProductForm(form: UntypedFormGroup, sourceCampaign: ICampaign): void {
    form.patchValue({
      productLine: sourceCampaign.product_line,
      family: sourceCampaign.family,
      subFamily: sourceCampaign.sub_family,
    })
  }

  private resolveValuesByActivationType(
    activationType: ActivationType,
    formValue: ICampaignForm,
  ): Partial<ICampaign> {
    if (activationType === ActivationType.CRM) {
      
      return {
        campaign_link_redirection: formValue[FormName.CRM].campaignLinkRedirection,
        campaign_type: formValue[FormName.CRM].campaignType,
        channel: formValue[FormName.CRM].channel,
        communication: formValue[FormName.CRM].communication,
        consumer_type: formValue[FormName.CRM].consumerType,
        cycle_or_journey: formValue[FormName.CRM].cycleOrJourney,
        funding_source: formValue[FormName.CRM].fundingSource,
        funnel: formValue[FormName.CRM].funnel,
        objective: formValue[FormName.CRM].objective,
        stage: formValue[FormName.CRM].stage,
      }
    } else {
      return {
        buying_method: formValue[FormName.MEDIA].buyingMethod,
        campaign_link_redirection: formValue[FormName.MEDIA].campaignLinkRedirection,
        campaign_type: formValue[FormName.MEDIA].campaignType,
        channel: formValue[FormName.MEDIA].channel,
        engine: formValue[FormName.MEDIA].engine,
        format: formValue[FormName.MEDIA].format,
        funding_source: formValue[FormName.MEDIA].fundingSource,
        funnel: formValue[FormName.MEDIA].funnel,
      }
    }
  }

  private setEngineValue(
    activationType: ActivationType,
    formValue: ICampaignForm,
  ): string {
    if (activationType === ActivationType.MEDIA) {
      return !!formValue.mediaType.engineFree
        ? formValue.mediaType.engineFree
        : formValue.mediaType.engine
    }
    return '.NSE'
  }
}
