import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignsModule } from '@features/campaigns/campaigns.module';
import { SharedModule } from '@shared/shared.module';
import { CampaignDuplicatePageRoutingModule } from './campaign-duplicate-page-routing.module';
import { CampaignDuplicatePageComponent } from './campaign-duplicate-page.component';


@NgModule({
  declarations: [CampaignDuplicatePageComponent],
  imports: [
    CommonModule,
    CampaignDuplicatePageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CampaignsModule,
    SharedModule
  ]
})
export class CampaignDuplicateModule { }
