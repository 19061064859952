import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  isDevMode,
} from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { AuthenticatorService } from '@aws-amplify/ui-angular'
import { Amplify } from 'aws-amplify'
import awsExports from 'src/aws-exports'
import { UserService } from '@core/services/user.service'
import { FormMode } from '@core/enums/form-mode.enum'
import { CognitoUser } from 'amazon-cognito-identity-js'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Campaign Naming Builder'

  constructor(
    /**
     * To add and use Angular Material custom svg icons
     */
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly userService: UserService,
    public authenticator: AuthenticatorService,
  ) {
    Amplify.configure(awsExports)
    console.log('is dev mode', isDevMode())

    /**
     * Register icons here (name, path)
     */
    this.matIconRegistry.addSvgIcon(
      'headphone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/headphone-symbol.svg',
      ),
    )
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/logout.svg',
      ),
    )
    this.matIconRegistry.addSvgIcon(
      'eraser',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/eraser-solid.svg',
      ),
    )
  }

  ngOnInit(): void {
    this.authenticator.subscribe((data: any) => {
      if (this.authenticator.authStatus === 'authenticated') {
        const typedUserData: CognitoUser = data.user; // const used specifically to specify 
        this.userService.userToken = typedUserData.getSignInUserSession().getIdToken().getJwtToken();
        this.userService.loggedUser = typedUserData;
        this.router.navigate(['campaigns', FormMode.CREATE])
        this.userService.isLogged = true;
      }
    })
  }
  // onAuthUIStateChange((authState, authData: CognitoUserInterface) => {
  //   if (authData) {
  //     this.userService.userToken = authData.signInUserSession.idToken.jwtToken
  //     this.userService.loggedUser = authData
  //   }
  //   if (!this.ref['destroyed']) {
  //     this._ngZone.run(() => {
  //       this.ref.detectChanges()
  //       if (authState == AuthState.SignedIn && authData) {
  //         this.router.navigate(['campaigns', FormMode.CREATE])
  //       }
  //     })
  //   }
  // })

  ngOnDestroy() {
    // return onAuthUIStateChange
  }
}