import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninPageComponent } from './signin-page.component';
import { AuthModule } from '@features/auth/auth.module';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { SigninPageRoutingModule } from './signin-page-routing.module';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
  declarations: [
    SigninPageComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SigninPageRoutingModule,
    AmplifyAuthenticatorModule,
    AuthModule,
    SharedModule
  ]
})
export class SigninPageModule { }
