import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/base.component';
import { CampaignService } from '../../services/campaign.service';
@Component({
  selector: 'app-campaign-delete-dialog',
  templateUrl: './campaign-delete-dialog.component.html',
  styleUrls: ['./campaign-delete-dialog.component.scss']
})
export class CampaignDeleteDialogComponent extends BaseComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CampaignDeleteDialogComponent>,    
    private readonly campaignService: CampaignService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  public onDelete(): void {
    this.campaignService.deleteCampaign(this.data.campaignId).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.closeDialog('delete_success')
      })
    ).subscribe(() => {
    });
  }

  private closeDialog(msg:string): void {
    this.dialogRef.close(msg);
  }

}
