import { Component, OnInit } from '@angular/core';
import { I18n } from 'aws-amplify';
import { AuthenticatorService, translations } from '@aws-amplify/ui-angular';

I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies({
  en: {
    Username: 'Username * (First letter of first name followed by full family name)',
  }
});

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  isLoading = true;

  constructor(
    public authenticator: AuthenticatorService

  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading= false
    }, 500);
  }

}
