import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@environment/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, retry, tap, shareReplay } from 'rxjs/operators';
import { ICampaignUrl } from '../models/campaign-url.model';
import { ICampaign, ICampaignSearchParams } from '../models/campaign.model';
import { ActivationType } from '@core/enums';
@Injectable({
  providedIn: 'root',
})

export class CampaignService {

  constructor(
    private http: HttpClient,
    private readonly notificationService: NotificationService,

    ) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    params: new HttpParams()
  };

  path = environment.APIEndPoint


  // Get campaigns
  // 
  public getCampaigns(param?: any): Observable<any> {
    let path = `${this.path}/campaigns`;
    if (param.markets) {
      path = `${this.path}/campaigns?markets=${param.markets}`;
    }
    if (param.owner) {
      path = `${this.path}/campaigns?owner=${param.owner}`;
    }
    return this.http
      .get(path, this.httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError<any>('get campaigns', [])),

        map(res => {
          return res
        })
        )  
  }

  public searchCampaigns(searchParams?: ICampaignSearchParams): Observable<ICampaign[]> {
    const path = `${this.path}/campaigns`;
    return this.http
    .post(path, searchParams ,this.httpOptions)
    .pipe(
      catchError(this.handleError<any>('search campaigns', [])),
      )
  }

  // Get single campaign
  public getCampaign(id: string): Observable<ICampaign> {
    const path = `${this.path}/campaign?campaignId=${id}`;
    return this.http
      .get(path, this.httpOptions)
      .pipe(catchError(this.handleError<any>('get campaign', [])));
  }

  // Create campaign
  public createCampaign(campaign: ICampaign): Observable<any> {
    const path = `${this.path}/campaign`;
    return this.http.post(path, campaign, this.httpOptions).pipe(
      tap((newCampaign: any) =>
        this.notificationService.success(`new campaign created : ${newCampaign.Item.id}`)

      ),
      catchError(this.handleError<any>('create campaign', campaign))
    );
  }
  // Update campaign
  public updateCampaign(campaign: ICampaign): Observable<ICampaign> {
    const path = `${this.path}/campaign`;
    return this.http
      .put(path, campaign, this.httpOptions)
      .pipe(
        tap((updatedCampaign: any) => {
          this.notificationService.success(`campaign updated : ${updatedCampaign.Item.id}`)

        }),
        catchError(this.handleError<any>('update campaign', []))
        );
  }

  // Delete campaign
  public deleteCampaign(id: number): Observable<ICampaign> {
    const path = `${this.path}/campaign?campaignId=${id}`;
    return this.http
      .delete(path, this.httpOptions)
      .pipe(
        shareReplay(),
        catchError(this.handleError<any>('delete campaign', [])));
  }

  /**
   * Generate url 
   * @param {ICampaign} c - campaign
   * @returns urls object { shortUrl: string, fullUrl: string}
   */
  public generateCampaignUrl(c: ICampaign): ICampaignUrl {
    let xSmallUrl: string;
    let shortUrl: string;
    let fullUrl: string;
    if (c.activation_type === ActivationType.MEDIA) {
      xSmallUrl = `${c.id}_${c.channel}_${c.format}_${c.optional}`;
      shortUrl = `${c.id}_${c.market}_${c.brand}_${c.product_line}_${c.family}_${c.sub_family}_${c.funnel}_${c.channel}_${c.format}`;
      fullUrl = `${c.id}_${c.market}_${c.language}_${c.brand}_${c.product_line}_${c.family}_${c.sub_family}_${c.funnel}_${c.channel}_${c.format}_${c.duration_type}_${c.start_month}_${c.campaign_link_redirection}_${c.engine}_${c.buying_method}_${c.campaign_type}_${c.optional}_${c.event_type}_${c.funding_source}`;
    }
    if (c.activation_type === ActivationType.CRM) {
      xSmallUrl = `${c.id}_${c.channel}_${c.optional}`;
      shortUrl = `${c.id}_${c.market}_${c.brand}_${c.product_line}_${c.family}_${c.sub_family}_${c.event_type}_${c.stage}_${c.cycle_or_journey}_${c.communication}_${c.campaign_type}_${c.objective}`;
      fullUrl = `${c.id}_${c.market}_${c.language}_${c.brand}_${c.product_line}_${c.family}_${c.sub_family}_${c.event_type}_${c.stage}_${c.cycle_or_journey}_${c.communication}_${c.campaign_type}_${c.objective}_${c.channel}_${c.consumer_type}_${c.campaign_link_redirection}_${c.duration_type}_${c.start_month}_${c.optional}_${c.funding_source}`;
    }
    return { xSmallUrl, shortUrl, fullUrl };
  }

  /**********************************
   *
   * PRIVATE METHODS
   *
   **********************************/

  /** Log a CampaignService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`CampaignService: ${message}`);
    console.error('message: ', message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);
      this.notificationService.error(`Something went wrong ... ${operation} failed! Please retry`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
