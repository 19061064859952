import { Injectable } from '@angular/core';
// import cryptoRandomString from 'crypto-random-string';

@Injectable({
  providedIn: 'root',
})
export class RandomIdGenerator {
  constructor() {}

  getRandomNumber(length) {
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  generateId() {
    const charRange = (start, end) => Array.from(Array(end-start), 
                                         (v, i) => String.fromCharCode(i + start))

    const digits = charRange(48, 48+10)             // 0 to 9
                   .concat(charRange(97, 97+26))  // a to b
                   .concat(charRange(65, 65+26))  // A to B

    const base = digits.length
    let result = "";
    let decimal: number = Math.floor(Date.now() / 1000)
    while(decimal >= base) {
      result = digits[decimal % base] + result
      decimal = Math.floor(decimal / base)
    }
    return digits[decimal] + result
}
}
