<div [formGroupName]="productFormName" id="productForm">
    <section>
        <h2>Promoted Product</h2>
        <!-- product line -->
        <mat-form-field appearance="outline">
          <mat-label>Product line</mat-label>
          <mat-select formControlName="productLine">
            <mat-option
              *ngFor="let product of productLines"
              [value]="product.value"
            >
              {{ product.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-icon class="icon-error">error</mat-icon>
            {{ errors.required }}
          </mat-error>
        </mat-form-field>

        <!-- family -->
        <mat-form-field appearance="outline">
          <mat-label>Family</mat-label>
          <mat-select formControlName="family">
            <mat-option
              *ngFor="let family of families"
              [value]="family.value"
            >
              {{ family.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-icon class="icon-error">error</mat-icon>
            {{ errors.required }}
          </mat-error>
        </mat-form-field>

        <!-- sub-family -->
        <mat-form-field appearance="outline">
          <mat-label>Sub-family or product</mat-label>
          <mat-select formControlName="subFamily">
            <mat-option
              *ngFor="let subFamily of subFamilies"
              [value]="subFamily.value"
            >
              {{ subFamily.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <mat-icon class="icon-error">error</mat-icon>
            {{ errors.required }}
          </mat-error>
        </mat-form-field>

        <mat-slide-toggle
          color="primary"
          formControlName="switchToggleProducts"
          >No specific promoted product
        </mat-slide-toggle>
      </section>
</div>