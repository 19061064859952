<div class="campaign-form content">
  <form [formGroup]="campaignForm" (ngSubmit)="onSubmit()">
    <div class="formgroup content">
      <div class="row">
        <!-- Country & Brand -->
        <section>
          <h2>Country & brand</h2>
          <!-- markets -->
          <mat-form-field appearance="outline">
            <mat-label>Market</mat-label>
            <mat-select formControlName="market">
              <mat-option *ngFor="let market of leadData.markets" [value]="market.value">
                {{ market.props.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              <mat-icon class="icon-error">error</mat-icon>
              {{ errors.required }}
            </mat-error>
          </mat-form-field>

          <!-- languages -->
          <mat-form-field appearance="outline">
            <mat-label>Language (optional)</mat-label>
            <mat-select formControlName="language">
              <mat-option *ngFor="let language of languages" [value]="language.value">
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- brands -->
          <mat-form-field appearance="outline">
            <mat-label>Brand</mat-label>
            <mat-select formControlName="brand">
              <mat-option *ngFor="let brand of brands" [value]="brand.value">
                {{ brand.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              <mat-icon class="icon-error">error</mat-icon>
              {{ errors.required }}
            </mat-error>
          </mat-form-field>
        </section>

        <!-- Events details -->
        <section>
          <h2>Events Details</h2>

          <!-- event type -->
          <mat-form-field appearance="outline">
            <mat-label>Event</mat-label>
            <mat-select formControlName="eventType">
              <div class="search-input">
                <mat-icon>search</mat-icon>
                <input matInput (keyup)="onSearch($event)" placeholder="Enter your search" />
              </div>

              <mat-option *ngFor="let event of filteredEvents" [value]="event.value">
                {{ event.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              <mat-icon class="icon-error">error</mat-icon>
              {{ errors.required }}
            </mat-error>
          </mat-form-field>

          <!-- duration type -->
          <mat-form-field appearance="outline">
            <mat-label>Duration type</mat-label>
            <mat-select formControlName="durationType">
              <mat-option *ngFor="let duration of leadData.durations" [value]="duration.value">
                {{ duration.props.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              <mat-icon class="icon-error">error</mat-icon>
              {{ errors.required }}
            </mat-error>
          </mat-form-field>

          <!-- duration type -> start month -->
          <mat-form-field appearance="outline">
            <mat-label>Start month (day is indicative and not used in reporting)</mat-label>
            <input matInput [matDatepicker]="dp" formControlName="startMonth" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)" panelClass="month-picker">
            </mat-datepicker>
            <mat-error>
              <mat-icon class="icon-error">error</mat-icon>
              {{ errors.required }}
            </mat-error>
          </mat-form-field>

          <!-- optional -->
          <mat-form-field appearance="outline">
            <mat-label>Free text (optional)</mat-label>
            <input matInput formControlName="optional" maxlength="60" />
          </mat-form-field>
        </section>

        <!-- Promoted Product -->
        <app-product-form [errors]="errors" [mode]="mode" [sourceCampaign]="sourceCampaign"></app-product-form>
      </div>

      <!-- Campaign Activation Type -->
      <div class="row">
        <section class="activation-type">
          <h2>Activation type</h2>
          <mat-radio-group formControlName="activationType" color="primary" class="activation-type"
            [ngClass]="{ 'activation-type-error': !!activationTypeError }">
            <mat-radio-button *ngFor="let type of leadData.activationTypes" [value]="type.value">{{ type.props.name }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="!!activationTypeError">
            <mat-icon class="icon-error">error</mat-icon>
            {{ errors.required }}
          </mat-error>
        </section>
      </div>

      <!-- Activation type campaign form -->
      <div class="activation-type-form">

        <!-- Digital / Media -->
        <div class="digital-form" *ngIf="activationType === 'DIG'">
          <app-media-form [errors]="errors" [mode]="mode" [sourceCampaign]="sourceCampaign"></app-media-form>
        </div>

        <!-- CRM -->
        <div class="crm-form" *ngIf="activationType === 'CRM'">
          <app-crm-form [errors]="errors" [mode]="mode" [sourceCampaign]="sourceCampaign"></app-crm-form>
        </div>
      </div>

      <div class="form-footer">
        <button mat-raised-button color="primary" type="submit" class="btn" *ngIf="mode === formMode.CREATE">
          Generate
          <mat-icon>play_arrow</mat-icon>
        </button>
        <button mat-raised-button color="primary" type="submit" class="btn" *ngIf="mode === formMode.UPDATE">
          Update
        </button>
        <button mat-raised-button color="primary" type="submit" class="btn" *ngIf="mode === formMode.DUPLICATE">
          Duplicate
        </button>
      </div>
    </div>
  </form>
</div>

<app-loader *ngIf="isLoading"></app-loader>