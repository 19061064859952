import { Clipboard } from '@angular/cdk/clipboard'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { ArrayHelper } from '@core/helpers/array.helper'

import { ILeadRessource } from '@core/models/lead-ressource.model'
import { IRessource } from '@core/models/ressource.model'

import { NotificationService } from '@core/services/notification.service'
import { ResourceService } from '@core/services/resource.service'
import { UserService } from '@core/services/user.service'

import { ICampaign, IfilterValues } from '@features/campaigns/models'

import { CampaignFormService } from '@features/campaigns/services/campaign-form.service'
import { CampaignService } from '@features/campaigns/services/campaign.service'
import { takeUntil } from 'rxjs/operators'
import { BaseComponent } from 'src/app/core/base.component'
import { CampaignListColumns, FormMode } from 'src/app/core/enums'
import { CampaignDeleteDialogComponent } from '../campaign-delete-dialog/campaign-delete-dialog.component'


@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent extends BaseComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    CampaignListColumns.ACTIVATION_TYPE,
    CampaignListColumns.URL_ID,
    CampaignListColumns.URL_MEDIUM_NAME,
    CampaignListColumns.USED,
    CampaignListColumns.COPY_URLS,
    CampaignListColumns.CREATED_DATE,
    CampaignListColumns.OPTIONS,
  ]
  dataSource = new MatTableDataSource<CampaignElement>()
  isLoading = false
  showNoResultsMsg = false;
  userMarkets!: string;

  userCampaigns: ICampaign[] = [];
  filteredDataArray = []

  filterValues: IfilterValues = {
    campaign_id: null,
    product_line: null,
    market: null,
    brand: null,
  }


  productLines: ILeadRessource[]
  activationType: ILeadRessource[]
  markets: ILeadRessource[]
  brands: IRessource[] = []


  campaignIdFilter = new UntypedFormControl()
  productsFilter = new UntypedFormControl()
  marketsFilter = new UntypedFormControl()
  brandsFilter = new UntypedFormControl()

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly campaignService: CampaignService,
    private readonly userService: UserService,
    private readonly clipboard: Clipboard,
    private readonly resourcesService: ResourceService,
    private readonly campaignFormService: CampaignFormService,
    private readonly arrayHelper: ArrayHelper,
    private readonly notificationService: NotificationService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.userMarkets = this.userService.getUserMarket();

    // get toolbar filter resources
    this.getToolbarFilterResources()
    this.handleFilterChanges()
  }


  get hasNoFilters() {
    const filters = Object.values(this.filterValues);
    return filters.every(val => val === null);
  }

  // Populate filter select fields
  getToolbarFilterResources(): void {
    const leadData = this.resourcesService.resolveLeadData()
    this.productLines = leadData.productLines
    this.activationType = leadData.activationTypes
    this.markets = leadData.markets
    this.brands = this.buildBrandListByUserMarkets(this.markets)
  }

  // Build brands list by user markets
  buildBrandListByUserMarkets(userMarkets: ILeadRessource[]): IRessource[] {
    let bufferArray = []
    userMarkets.map((m) => {
      bufferArray.push(
        this.campaignFormService.resolveDependencies(
          m.value,
          this.resourcesService.getResources().brands,
        ),
      )
    })
    bufferArray = bufferArray.flat();
    return this.arrayHelper.removeDuplicatedObjectsFromArray(bufferArray)
  }

  buildCampaignSourceData(campaigns: ICampaign[]): CampaignElement[] {
    let res: CampaignElement[] = []
    campaigns.map((c) => {
      res.push({
        activationType: c.activation_type,
        urlId: c.id,
        urlShortName: c.url_xSmall,
        urlMediumName: c.url_short,
        urlFullName: c.url_full,
        used: c.url_state === 'USED',
        associatedCampaigns: null,
        createdDate: c.created_date,
      })
    })
    return res
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  public searchByFilter() {
    this.isLoading = true;
    this.resolveSearchType(this.filterValues);
  }

  public clearFilter() {
    this.router.navigate(['/'])
    // this.campaignIdFilter.reset()
    // this.clearFiltersFields();
    // this.showNoResultsMsg = false;
    // this.dataSource.data = this.userCampaigns.length ? this.buildCampaignSourceData(this.userCampaigns) : [];
  }

  public onCreateNew() {
    this.router.navigate(['campaigns', FormMode.CREATE])
  }

  public onGetMyCampaigns() {
    this.isLoading = true;
    this.campaignService
      .getCampaigns({ owner: this.userService.getUserEmail() })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userCampaigns = [...data];
        this.dataSource.data = this.buildCampaignSourceData(this.userCampaigns)
        this.showNoResultsMsg = !this.dataSource.data.length;
        this.isLoading = false
      })

  }

  public onCopyToClipboard(source: string): void {
    if (!!source) {
      const urlCopied = this.clipboard.copy(source)
      if (!!urlCopied) {
        this.openSnackBar()
      }
    }
  }

  public onDisplay(item: CampaignElement): void {
    this.router.navigate(['campaigns', 'detail', item.urlId])
  }

  public onModify(item: CampaignElement): void {
    this.router.navigate(['campaigns', 'update', item.urlId])
  }

  public onDuplicate(item: CampaignElement): void {
    this.router.navigate(['campaigns', 'duplicate', item.urlId])
  }

  public onDelete(item: CampaignElement): void {
    let dialogRef = this.dialog.open(CampaignDeleteDialogComponent, {
      data: {
        campaignId: item.urlId,
        campaignName: item.urlFullName,
      },
      panelClass: 'generated-url-dialog-container',
      minWidth: 700,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.isLoading = true;

        this.campaignService
          .getCampaigns({ owner: this.userService.getUserEmail() })
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.userCampaigns = [...data];
            this.dataSource.data = this.buildCampaignSourceData(this.userCampaigns)
            this.isLoading = false
            this.notificationService.success('Campaign successfully deleted!');
          })

      }
    })
  }



  // PRIVATE METHODS

  private handleFilterChanges(): void {
    this.campaignIdFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.filterValues.campaign_id = value || null;
          this.resolveSearchFieldsState(value);

        });

    this.productsFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.filterValues.product_line = value || null;
        });

    this.marketsFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.filterValues.market = value || null;
      });

    this.brandsFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.filterValues.brand = value || null;
      });
  }

  private openSnackBar() {
    this.notificationService.success('Url successfully copied!')
  }

  /**
   * 
   * @param {IfilterValues} filterValues 
   */
  private resolveSearchType(filterValues: IfilterValues): void {
    if (filterValues.campaign_id) {
      this.campaignService.getCampaign(filterValues.campaign_id).pipe(
        takeUntil(this.destroy$),
      ).subscribe((res) => {
        this.isLoading = false;

        // Check if founded campaign is in user markets
        if ((!!res && (this.userMarkets.split(' ').includes(res.market))) ||
          (!!res && this.userMarkets === '*')) {
          this.dataSource.data = this.buildCampaignSourceData([res]);
        } else {
          this.dataSource.data = [];
        }

        this.showNoResultsMsg = !this.dataSource.data.length;
      }
      );
    } else {
      this.campaignService.searchCampaigns({ filter: this.filterValues, markets: this.userService.getUserMarket() }).pipe(
        takeUntil(this.destroy$),
      ).subscribe((res) => {
        this.isLoading = false;
        this.dataSource.data = this.buildCampaignSourceData(res)
        this.showNoResultsMsg = !this.dataSource.data.length;

      });
    }
  }

  private resolveSearchFieldsState(value: string | null) {
    if (!!value && value.length) {
      this.disableFiltersFields();
      this.clearFiltersFields();
    } else {
      this.enableFiltersFields();
    }
  }

  private disableFiltersFields(): void {
    this.productsFilter.disable();
    this.marketsFilter.disable();
    this.brandsFilter.disable();
  }

  private enableFiltersFields(): void {
    this.productsFilter.enable();
    this.marketsFilter.enable();
    this.brandsFilter.enable();
  }

  private clearFiltersFields(): void {
    this.productsFilter.reset()
    this.marketsFilter.reset()
    this.brandsFilter.reset()
  }

}

export interface CampaignElement {
  activationType: string
  urlId: string
  urlShortName: string
  urlMediumName: string
  urlFullName: string
  used: boolean
  associatedCampaigns: number
  createdDate: string
}
