import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignsModule } from '@features/campaigns/campaigns.module';
import { CampaignUrlDialogComponent } from '@features/campaigns/components/campaign-url-dialog/campaign-url-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { CampaignCreatePageRoutingModule } from './campaign-create-page-routing.module';
import { CampaignCreatePageComponent } from './campaign-create-page.component';



@NgModule({
    declarations: [CampaignCreatePageComponent],
    imports: [
        CommonModule,
        CampaignCreatePageRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CampaignsModule,
        SharedModule
    ]
})
export class CampaignCreatePageModule { }
