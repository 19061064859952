import { Component } from '@angular/core';
import { FormMode } from '../../core/enums/form-mode.enum';


@Component({
  selector: 'app-campaign-create-page',
  templateUrl: './campaign-create-page.component.html',
  styleUrls: ['./campaign-create-page.component.scss'],
})
export class CampaignCreatePageComponent {

  public formMode = FormMode

}
