import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { AmplifyUser } from '@aws-amplify/ui'
import { ErrorHandler } from '@core/helpers/error.handler'
import { ILeadData, ILeadRessource, IRessource } from '@core/models'
import shared_eventTypesData from '@core/resources/shared_event_types.json'
import { NotificationService } from '@core/services/notification.service'
import { ResourceService } from '@core/services/resource.service'
import { UserService } from '@core/services/user.service'
import { CampaignUrlDialogComponent } from '@features/campaigns/components/campaign-url-dialog/campaign-url-dialog.component'
import { CampaignFormControl, ICampaignForm } from '@features/campaigns/models/campaign-form.model'
import { ICampaignUrl } from '@features/campaigns/models/campaign-url.model'
import { ICampaign } from '@features/campaigns/models/campaign.model'
import { CampaignFormService } from '@features/campaigns/services/campaign-form.service'
import { CampaignService } from '@features/campaigns/services/campaign.service'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BaseComponent } from 'src/app/core/base.component'
import { FormMode, ListSortMode } from 'src/app/core/enums'
import dayjs, { ConfigType } from 'dayjs'


@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
})
export class CampaignFormComponent extends BaseComponent implements OnInit {
  @Input() mode: string

  sourceCampaign: ICampaign

  isLoading = false
  date = new UntypedFormControl()

  campaignForm: UntypedFormGroup
  createdCampaign: ICampaign
  formMode = FormMode

  showErrorsSummary = false
  leadData: ILeadData

  activationType = null
  activationTypeError = false
  errors: any = {}

  markets: ILeadRessource[]
  languages: IRessource[]
  brands: IRessource[]
  events: IRessource[]
  filteredEvents: IRessource[]
  channels: IRessource[]

  // Date picker
  currentYear = dayjs().year()
  currentMonth = dayjs().month()

  generatedUrl: ICampaignUrl
  resources: any

  loggedUser: AmplifyUser
  userMarkets: string

  constructor(
    public dialog: MatDialog,
    private readonly fb: UntypedFormBuilder,
    private readonly errorHandler: ErrorHandler,
    private readonly userService: UserService,
    private readonly campaignService: CampaignService,
    private readonly resourceService: ResourceService,
    private readonly campaignFormService: CampaignFormService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();

  }

  ngOnInit(): void {
    this.loggedUser = this.userService.loggedUser
    this.userMarkets = this.userService.getUserMarket()
    this.initForm()
    this.initData()
    this.configFormByMode(this.mode)
    this.handleFormChanges()
    this.initFormErrors()
  }

  public onSearch(event: any): void {
    this.filteredEvents = this.events
    if (event.target.value.length) {
      this.filteredEvents = this.search(event.target.value)
    }
  }
  // Filter the states list and send back to populate the selectedStates
  search(value: string) {
    let filter = value.toLowerCase()
    return this.events.filter((option) =>
      option.name.toLowerCase().includes(filter),
    )
  }

  configFormByMode(mode: string) {
    // Update || display -> get campaign by Id and populate fields values
    if (mode !== FormMode.CREATE) {
      this.isLoading = true
      let campaignUrlId = this.activatedRoute.snapshot.params.id
      this.campaignService
        .getCampaign(campaignUrlId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (campaign: ICampaign) => {
            if (!!campaign) {
              if (
                this.userMarkets
                  .split(' ')
                  .some(
                    (market) => market === campaign.market || market === '*',
                  )
              ) {
                this.sourceCampaign = campaign
                this.campaignFormService.populateCampaignForm(
                  this.campaignForm,
                  campaign,
                )
                this.isLoading = false

                if (mode === FormMode.DISPLAY) {
                  this.campaignForm.disable({ emitEvent: false })
                }
              } else {
                // Unauthorized Access
                this.notificationService.error('UNAUTHORIZED ACCESS')
                this.router.navigate(['campaigns', 'create'])
              }
            } else {
              // No campaign founded
              this.notificationService.error('No campaign founded!')
              this.router.navigate(['campaigns', 'create'])
            }
          },
          (err) => {
            this.notificationService.error('OUPS... Error ocurred!')
          },
        )
    }
  }

  /**
   * Init lead data -> parent data with dependencies
   */
  private initData(): void {
    this.leadData = this.resourceService.resolveLeadData()
    this.resources = this.resourceService.getResources()
  }

  private initForm() {
    this.campaignForm = this.fb.group({
      market: ['', [Validators.required]],
      language: [{ value: '', disabled: true }],
      brand: [{ value: '', disabled: true }, [Validators.required]],
      activationType: ['', [Validators.required]],
      eventType: [{ value: '', disabled: true }, [Validators.required]],
      durationType: ['', [Validators.required]],
      startMonth: [{ value: undefined, disabled: true }, [Validators.required]],
      optional: [''],
      media: [''],
    })
  }

  private initFormErrors(): void {
    this.errors = {
      required: 'Required field',
    }
    this.errorHandler.handleErrors(this.campaignForm, this.errors)
  }

  private handleFormChanges(): void {
    // market changes
    this.campaignForm
      .get(CampaignFormControl.MARKET)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleMarketChanges(value)
      })

    // optional free text
    this.campaignForm
      .get(CampaignFormControl.OPTIONAL)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleOptionalTxtChanges(value)
      })

    // activation types
    this.campaignForm
      .get(CampaignFormControl.ACTIVATION_TYPE)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (!!value) {
          this.handleActivationTypeChanges(value)
        }
      })
    // duration changes
    this.campaignForm
      .get(CampaignFormControl.DURATION_TYPE)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.handleDurationTypeChanges(value)
      })
  }

  /**
   * Function used when chosing a year on datePicker
   * Setting up a date based on today but switching its year with the one picked by the user
   * @param normalizedYear Date set on 1st January of picked date
   */
  chosenYearHandler(normalizedYear: Date): void {
    const pickedUpYear = dayjs(normalizedYear).get('year');
    const now = dayjs().year(pickedUpYear);
    this.date.setValue(now)
    return;
  }

  // start month set month value
  chosenMonthHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<dayjs.Dayjs>,
  ) {
    const pickedUpMonth = dayjs(normalizedMonth).get('month');
    const datePicked = dayjs(this.date.value).month(pickedUpMonth);
    this.campaignForm.get('startMonth').setValue(datePicked.toDate())
    this.date.setValue(datePicked)
    datepicker.close()
  }

  public onSubmit(): void {
    if (
      (this.mode == FormMode.CREATE &&
        this.campaignForm.dirty &&
        this.campaignForm.valid) ||
      (this.mode == FormMode.UPDATE && this.campaignForm.valid) ||
      (this.mode == FormMode.DUPLICATE && this.campaignForm.valid)
    ) {
      this.isLoading = true
      this.showErrorsSummary = false
      this.createdCampaign = this.parseForm(this.mode)

      this.createOrUpdateCampaign(this.createdCampaign, this.mode)
        .pipe(takeUntil(this.destroy$))
        .subscribe((campaign) => {
          this.isLoading = false
          this.openDialog(campaign.Item)
        })
    } else {
      this.checkActivationTypeError()
      this.notificationService.requiredFields(
        'Please fill all required fields!',
      )
    }
  }

  private checkActivationTypeError(): void {
    if (
      this.campaignForm.get(CampaignFormControl.ACTIVATION_TYPE).errors &&
      this.campaignForm.get(CampaignFormControl.ACTIVATION_TYPE).hasError('required')
    ) {
      this.activationTypeError = true
    }
  }

  private parseForm(mode: FormMode): ICampaign {
    const formValue: ICampaignForm = this.campaignForm.value
    // Resolve values according to mode
    let createdCampaign =
      this.campaignFormService.resolveUrlCampaignValuesByMode(
        formValue,
        mode,
        this.sourceCampaign,
      )
    // generate url
    this.generatedUrl =
      this.campaignService.generateCampaignUrl(createdCampaign)
    createdCampaign = {
      ...createdCampaign,
      url_xSmall: this.generatedUrl.xSmallUrl,
      url_short: this.generatedUrl.shortUrl,
      url_full: this.generatedUrl.fullUrl,
      owner: this.userService.loggedUser.attributes.email,
    }
    return createdCampaign
  }

  private createOrUpdateCampaign(
    campaign: ICampaign,
    mode: FormMode,
  ): Observable<any> {
    if (mode !== FormMode.UPDATE) {
      return this.campaignService.createCampaign(campaign)
    } else {
      return this.campaignService.updateCampaign(campaign)
    }
  }

  public openDialog(campaign: ICampaign): void {
    let dialogRef = this.dialog.open(CampaignUrlDialogComponent, {
      data: {
        campaignUrlId: campaign.id,
        xSmallUrl: this.generatedUrl.xSmallUrl,
        shortUrl: this.generatedUrl.shortUrl,
        fullUrl: this.generatedUrl.fullUrl,
        utm_source: campaign.engine,
        utm_medium:
          this.resources.channels[campaign.activation_type][campaign.channel]
            .utm_medium,
      },
      panelClass: 'generated-url-dialog-container',
      minWidth: 700,
      disableClose: true,
      autoFocus: false,
    })
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.resolveRouteAfterClose(data.action, data.campaignUrlId)
      })
  }

  /***********************************************
   * PRIVATE METHODS
   ***********************************************/

  private resolveRouteAfterClose(action: string, campaignId?: string) {
    switch (action) {
      case FormMode.CREATE:
        this.router.navigate(['campaigns', FormMode.CREATE])
        break
      case FormMode.UPDATE:
        this.router.navigate(['campaigns', FormMode.UPDATE, campaignId])
        break

      case FormMode.DUPLICATE:
        this.router.navigate(['campaigns', FormMode.DUPLICATE, campaignId])
        break

      default:
        this.router.navigate(['/'])
    }
  }

  /**
   * Add shared events to market events and sort
   * @param {IRessource[]} events market events type 
   * @returns {IRessource[]} events
   */
  private buildEvents(events: IRessource[]): IRessource[] {
    let sharedEventTypes: IRessource[] = []
    for (const [key, val] of Object.entries(shared_eventTypesData)) {
      sharedEventTypes.push({ name: val.name, value: key })
    }
    events.push(...sharedEventTypes)
    return events.sort((a, b) => (a.value > b.value ? 1 : -1))
  }

  private handleOptionalTxtChanges(value: string) {
    if (!!value) {
      // Prevent from spaces
      const forbiddenCharacters = /\s+/g
      if (forbiddenCharacters.test(value)) {
        this.campaignForm
          .get(CampaignFormControl.OPTIONAL)
          .setValue(value.replace(forbiddenCharacters, ''))
      }
    }
  }

  private handleMarketChanges(value: string) {
    // Reset potential dependents fields value
    this.campaignForm.get(CampaignFormControl.BRAND).reset()
    this.campaignForm.get(CampaignFormControl.EVENT_TYPE).reset()
    // Resolve dependencies language, brand, event
    this.languages = this.campaignFormService.resolveDependencies(
      value,
      this.resources.languages,
    )
    this.brands = this.campaignFormService.resolveDependencies(
      value,
      this.resources.brands,
      ListSortMode.PER_ORIGIN_ORDER
    )
    // build events
    this.events = this.buildEvents(
      this.campaignFormService.resolveDependencies(
        value,
        this.resources.events,
      ),
    )
    this.filteredEvents = this.events
    // Resolve fields state
    this.campaignFormService.resolveFieldState(
      CampaignFormControl.LANGUAGE,
      this.campaignForm,
      this.languages,
    )
    this.campaignFormService.resolveFieldState(
      CampaignFormControl.BRAND,
      this.campaignForm,
      this.brands,
    )
    this.campaignFormService.resolveFieldState(
      CampaignFormControl.EVENT_TYPE,
      this.campaignForm,
      this.events,
    )
  }

  private handleActivationTypeChanges(value: string) {
    this.activationType = value
    this.activationTypeError = false
  }

  private handleDurationTypeChanges(value: string) {
    if (this.campaignForm.get(CampaignFormControl.DURATION_TYPE).value == 'BU') {
      this.campaignForm.get('startMonth').enable()
    } else {
      this.campaignForm.get('startMonth').disable()
      this.campaignForm.get('startMonth').reset()
      this.campaignForm.updateValueAndValidity()
    }
  }
}
