import { Injectable } from '@angular/core';
import { IRessource } from '@core/models/ressource.model';


@Injectable({
    providedIn: 'root'
})
export class ArrayHelper {

    removeDuplicatedObjectsFromArray(array): any[] {
        return array.reduce((unique, o) => {
            if(!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
        },[]);
    }

    sortResourcesArray(array?: IRessource[]) {
        return array?.sort((a, b) => (a.name > b.name ? 1 : -1))
    }

    sortResourcesArrayByValue(array?: IRessource[]) {
        return array?.sort((a, b) => (a.value > b.value ? 1 : -1))

    }

}