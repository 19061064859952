import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignsModule } from '@features/campaigns/campaigns.module';
import { SharedModule } from '@shared/shared.module';
import { CampaignUpdatePageRoutingModule } from './campaign-update-page-routing.module';
import { CampaignUpdatePageComponent } from './campaign-update-page.component';

@NgModule({
  declarations: [CampaignUpdatePageComponent],
  imports: [
    CommonModule,
    CampaignUpdatePageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CampaignsModule,
    SharedModule
  ]
})
export class CampaignUpdateModule {}
